import { skipHydrate } from 'pinia'
import type { BuyInStore } from '#types/buyInStore'

export const useBuyInStoreStore = defineStore('buyInStore', () => {
  const auth = useAuthStore()

  const emptyStoreInfo = { storeId: '', storeName: '', storeEmployeeId: '' }

  const storeInfo = skipHydrate(
    useSessionStorage<BuyInStore>('buyInStore', { ...emptyStoreInfo })
  )
  const employeeConnected = skipHydrate(computed(() => {
    return storeInfo.value.storeEmployeeId && storeInfo.value.storeId
  }))

  const setStoreInfo = (storeData: BuyInStore) => {
    storeInfo.value = storeData
  }

  const clearSession = () => {
    sessionStorage.removeItem('buyInStore')
    storeInfo.value = { ...emptyStoreInfo }
    auth.logout()
  }

  return {
    employeeConnected,
    storeInfo,
    setStoreInfo,
    clearSession,
  }
})
