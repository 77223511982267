<template>
  <div class="relative" :class="{ 'h-full': !images }">
    <base-picture v-if="images" class="full" v-bind="images" fit="cover" :lazy />
    <div
      v-style:c="titleColor"
      class="flex justify-center p-4"
      :class="[titleStyle, titlePosition === 'middle' ? 'items-center' : 'items-end', { 'absolute-0': images }]"
    >
      <p v-style:bg="backdrop" class="p-2 text-center md:p-4">
        {{ title }}
      </p>
    </div>
    <base-link v-if="target?.url" :to="target.url" :aria-label="title" class="absolute-0" />
  </div>
</template>

<script lang="ts" setup>
import type { CategoryCardContent } from '#types/components/cms/category-card'

const { content } = defineProps<{ content: CategoryCardContent }>()

const { getMedia } = useCms()
const lazy = inject(LazyMedia)

const { title, titleStyle, titleColor, titlePosition, target, media, backdrop } = content
const { images } = getMedia(media)
</script>
