import type { FetchContext } from 'ofetch'
import type { ContentPagePageItems } from '#root/api/clients/content/data-contracts'

interface GenericEspotSection {
  name: string
  items?: ContentPagePageItems
  lazy?: boolean
}

interface GenericEspotsResponse {
  content: {
    sections?: GenericEspotSection[]
    sectionsMap?: Record<string, GenericEspotSection>
  }
}

export const mergeInheritedEspotSections = ({ response }: FetchContext<GenericEspotsResponse>) => {
  if (response?._data?.content.sections) {
    const mergedSections = response?._data?.content.sections.reduce((acc, { name, items = [] }) => {
      name = name.replace('-inherited', '')

      return ({
        ...acc,
        [name]: items.length
          ? {
              name,
              lazy: name.includes('lazy'),
              items: acc[name]?.items ? [...acc[name].items || [], ...items] : items
            }
          : (acc[name] || null)
      })
    }, {} as Record<string, GenericEspotSection>)

    response._data.content.sectionsMap = mergedSections
    response._data.content.sections = Object.values(mergedSections)
  }
}
