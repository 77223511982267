/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type {
  AnswerVoteData,
  AskQuestionData,
  AutoSuggestData,
  CatalogData,
  DetailsData,
  GetStoreInventoryData,
  GetStoreInventoryPayload,
  InventoryData,
  NotifyData,
  NotifyPayload,
  ProductAnswersVoteRequest,
  ProductReviewsFlagRequest,
  ProductReviewsQuestionsAskRequest,
  ProductReviewsVoteRequest,
  ProductSetData,
  ProductSetsData,
  ProductWidgetRecommendationsData,
  QuestionsData,
  ReviewFlagData,
  ReviewTranslateData,
  ReviewTranslatePayload,
  ReviewVoteData,
  ReviewsData,
  SearchData,
} from "./data-contracts";

export class Products extends HttpClient {
  static paths = {
    details: "/products/v2/products/:productId/details",
    getStoreInventory: "/products/v1/products/:productId/availability",
    inventory: "/products/v2/products/:productId/inventory",
    reviews: "/products/v1/products/:productId/reviews",
    reviewFlag: "/products/v1/products/:productId/review/:reviewId/flag",
    reviewVote: "/products/v1/products/:productId/review/:reviewId/vote",
    productSets: "/products/v2/products/:productId/productSets",
    productSet: "/products/v2/products/:productSetId/productSet",
    reviewTranslate: "/products/v1/review/:reviewId/translate",
    catalog: "/products/v2/catalog",
    notify: "/products/v1/notifications",
    search: "/products/v2/search/productSearch",
    autoSuggest: "/products/v2/search/autoSuggest",
    questions: "/products/v1/products/:productId/reviews/questions",
    askQuestion: "/products/v1/products/:productId/reviews/questions",
    answerVote: "/products/v1/products/:productId/review/:answerId/questions/vote",
    productWidgetRecommendations: "/products/v1/widgetRecommendations",
  };
  static mockDataPaths = {
    details: "pdp",
    inventory: "inventory",
    reviews: "reviews",
    reviewFlag: "reviewFlag",
    reviewVote: "reviewVote",
    productSets: "productSets",
    productSet: "productSet",
    reviewTranslate: "reviewTranslate",
    catalog: "plp",
    autoSuggest: "searchAutoSuggest",
    questions: "questions",
    askQuestion: "askQuestion",
    answerVote: "answerVote",
  };
  static mockedPaths = {
    details: false,
    getStoreInventory: false,
    inventory: false,
    reviews: false,
    reviewFlag: false,
    reviewVote: false,
    productSets: false,
    productSet: false,
    reviewTranslate: false,
    catalog: false,
    notify: false,
    search: false,
    autoSuggest: false,
    questions: false,
    askQuestion: false,
    answerVote: false,
    productWidgetRecommendations: false,
  };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "product");
  }

  /**
   * No description
   *
   * @tags PDP, PLP
   * @name Details
   * @summary Product info and details
   * @request GET:/products/v2/products/{productId}/details
   */
  details = <ResT = DetailsData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    productId: MaybeRefOrGetter<string>,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
      /** Identifies if the requst is a performance based preload */
      preload?: boolean;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v2/products/:productId/details")
        ? computed(() => mockPath("Products", "details", `productId:${toValue(productId)}`))
        : computed(() => `/products/v2/products/${toValue(productId)}/details`),
      method: "GET",
      query: query,
      key: "/products/v2/products/:productId/details",
      apiName: "Products.details",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP, PLP
   * @name Details
   * @summary Product info and details
   * @request GET:/products/v2/products/{productId}/details
   */
  $details = (
    productId: string,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
      /** Identifies if the requst is a performance based preload */
      preload?: boolean;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<DetailsData>({
      path: isMocked("/products/v2/products/:productId/details")
        ? mockPath("Products", "details", `productId:${toValue(productId)}`)
        : `/products/v2/products/${productId}/details`,
      method: "GET",
      query: query,
      apiName: "Products.details",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP, PLP
   * @name GetStoreInventory
   * @summary Product store inventory
   * @request POST:/products/v1/products/{productId}/availability
   */
  getStoreInventory = <
    ResT = GetStoreInventoryData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    productId: MaybeRefOrGetter<string>,
    data: GetStoreInventoryPayload,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
      /** Context for which we check availability */
      context?: "robis";
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v1/products/:productId/availability")
        ? computed(() => mockPath("Products", "getStoreInventory", `productId:${toValue(productId)}`))
        : computed(() => `/products/v1/products/${toValue(productId)}/availability`),
      method: "POST",
      query: query,
      body: data,
      key: "/products/v1/products/:productId/availability",
      apiName: "Products.getStoreInventory",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP, PLP
   * @name GetStoreInventory
   * @summary Product store inventory
   * @request POST:/products/v1/products/{productId}/availability
   */
  $getStoreInventory = (
    productId: string,
    data: GetStoreInventoryPayload,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
      /** Context for which we check availability */
      context?: "robis";
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<GetStoreInventoryData>({
      path: isMocked("/products/v1/products/:productId/availability")
        ? mockPath("Products", "getStoreInventory", `productId:${toValue(productId)}`)
        : `/products/v1/products/${productId}/availability`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Products.getStoreInventory",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP, PLP
   * @name Inventory
   * @summary Product info and details
   * @request GET:/products/v2/products/{productId}/inventory
   */
  inventory = <ResT = InventoryData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    productId: MaybeRefOrGetter<string>,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v2/products/:productId/inventory")
        ? computed(() => mockPath("Products", "inventory", `productId:${toValue(productId)}`))
        : computed(() => `/products/v2/products/${toValue(productId)}/inventory`),
      method: "GET",
      query: query,
      key: "/products/v2/products/:productId/inventory",
      apiName: "Products.inventory",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP, PLP
   * @name Inventory
   * @summary Product info and details
   * @request GET:/products/v2/products/{productId}/inventory
   */
  $inventory = (
    productId: string,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<InventoryData>({
      path: isMocked("/products/v2/products/:productId/inventory")
        ? mockPath("Products", "inventory", `productId:${toValue(productId)}`)
        : `/products/v2/products/${productId}/inventory`,
      method: "GET",
      query: query,
      apiName: "Products.inventory",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name Reviews
   * @summary Product reviews
   * @request GET:/products/v1/products/{productId}/reviews
   */
  reviews = <ResT = ReviewsData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    productId: MaybeRefOrGetter<string>,
    query?: {
      /** Return only reviews with images. */
      image_only?: string;
      /** The number of Product Reviews you want to return. */
      pagingSize?: number;
      /** The Review where you want to start returning Product Reviews from. If you want reviews from page one, omit this parameter. */
      pagingFrom?: number;
      /** The order in which you want to return your Product Reviews. */
      sort?: string;
      /** Filtering is performed by appending filters=$FILTER1:$VALUE1,$FILTER2:$VALUE2, where $FILTER:$VALUE are key/value pair sourced from results.rollup.properties[] of the response. */
      filters?: string;
      /**
       * Locale used for cache key entry only.
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
      /** Return all reviews syndicated for a given locale. */
      getAll?: boolean;
      /** Search term used to filer elements. */
      q?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v1/products/:productId/reviews")
        ? computed(() => mockPath("Products", "reviews", `productId:${toValue(productId)}`))
        : computed(() => `/products/v1/products/${toValue(productId)}/reviews`),
      method: "GET",
      query: query,
      key: "/products/v1/products/:productId/reviews",
      apiName: "Products.reviews",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name Reviews
   * @summary Product reviews
   * @request GET:/products/v1/products/{productId}/reviews
   */
  $reviews = (
    productId: string,
    query?: {
      /** Return only reviews with images. */
      image_only?: string;
      /** The number of Product Reviews you want to return. */
      pagingSize?: number;
      /** The Review where you want to start returning Product Reviews from. If you want reviews from page one, omit this parameter. */
      pagingFrom?: number;
      /** The order in which you want to return your Product Reviews. */
      sort?: string;
      /** Filtering is performed by appending filters=$FILTER1:$VALUE1,$FILTER2:$VALUE2, where $FILTER:$VALUE are key/value pair sourced from results.rollup.properties[] of the response. */
      filters?: string;
      /**
       * Locale used for cache key entry only.
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
      /** Return all reviews syndicated for a given locale. */
      getAll?: boolean;
      /** Search term used to filer elements. */
      q?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<ReviewsData>({
      path: isMocked("/products/v1/products/:productId/reviews")
        ? mockPath("Products", "reviews", `productId:${toValue(productId)}`)
        : `/products/v1/products/${productId}/reviews`,
      method: "GET",
      query: query,
      apiName: "Products.reviews",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name ReviewFlag
   * @summary Flag review
   * @request POST:/products/v1/products/{productId}/review/{reviewId}/flag
   */
  reviewFlag = <ResT = ReviewFlagData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    productId: MaybeRefOrGetter<string>,
    reviewId: MaybeRefOrGetter<string>,
    data: ProductReviewsFlagRequest,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v1/products/:productId/review/:reviewId/flag")
        ? computed(() =>
            mockPath("Products", "reviewFlag", `productId:${toValue(productId)}.reviewId:${toValue(reviewId)}`),
          )
        : computed(() => `/products/v1/products/${toValue(productId)}/review/${toValue(reviewId)}/flag`),
      method: "POST",
      query: query,
      body: data,
      key: "/products/v1/products/:productId/review/:reviewId/flag",
      apiName: "Products.reviewFlag",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name ReviewFlag
   * @summary Flag review
   * @request POST:/products/v1/products/{productId}/review/{reviewId}/flag
   */
  $reviewFlag = (
    productId: string,
    reviewId: string,
    data: ProductReviewsFlagRequest,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<ReviewFlagData>({
      path: isMocked("/products/v1/products/:productId/review/:reviewId/flag")
        ? mockPath("Products", "reviewFlag", `productId:${toValue(productId)}.reviewId:${toValue(reviewId)}`)
        : `/products/v1/products/${productId}/review/${reviewId}/flag`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Products.reviewFlag",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name ReviewVote
   * @summary Vote for review
   * @request POST:/products/v1/products/{productId}/review/{reviewId}/vote
   */
  reviewVote = <ResT = ReviewVoteData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    productId: MaybeRefOrGetter<string>,
    reviewId: MaybeRefOrGetter<number>,
    data: ProductReviewsVoteRequest,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v1/products/:productId/review/:reviewId/vote")
        ? computed(() =>
            mockPath("Products", "reviewVote", `productId:${toValue(productId)}.reviewId:${toValue(reviewId)}`),
          )
        : computed(() => `/products/v1/products/${toValue(productId)}/review/${toValue(reviewId)}/vote`),
      method: "POST",
      query: query,
      body: data,
      key: "/products/v1/products/:productId/review/:reviewId/vote",
      apiName: "Products.reviewVote",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name ReviewVote
   * @summary Vote for review
   * @request POST:/products/v1/products/{productId}/review/{reviewId}/vote
   */
  $reviewVote = (
    productId: string,
    reviewId: number,
    data: ProductReviewsVoteRequest,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<ReviewVoteData>({
      path: isMocked("/products/v1/products/:productId/review/:reviewId/vote")
        ? mockPath("Products", "reviewVote", `productId:${toValue(productId)}.reviewId:${toValue(reviewId)}`)
        : `/products/v1/products/${productId}/review/${reviewId}/vote`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Products.reviewVote",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name ProductSets
   * @summary All product sets of a product
   * @request GET:/products/v2/products/{productId}/productSets
   */
  productSets = <ResT = ProductSetsData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    productId: MaybeRefOrGetter<string>,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v2/products/:productId/productSets")
        ? computed(() => mockPath("Products", "productSets", `productId:${toValue(productId)}`))
        : computed(() => `/products/v2/products/${toValue(productId)}/productSets`),
      method: "GET",
      query: query,
      key: "/products/v2/products/:productId/productSets",
      apiName: "Products.productSets",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name ProductSets
   * @summary All product sets of a product
   * @request GET:/products/v2/products/{productId}/productSets
   */
  $productSets = (
    productId: string,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<ProductSetsData>({
      path: isMocked("/products/v2/products/:productId/productSets")
        ? mockPath("Products", "productSets", `productId:${toValue(productId)}`)
        : `/products/v2/products/${productId}/productSets`,
      method: "GET",
      query: query,
      apiName: "Products.productSets",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name ProductSet
   * @summary Details of a product set
   * @request GET:/products/v2/products/{productSetId}/productSet
   */
  productSet = <ResT = ProductSetData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    productSetId: MaybeRefOrGetter<string>,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v2/products/:productSetId/productSet")
        ? computed(() => mockPath("Products", "productSet", `productSetId:${toValue(productSetId)}`))
        : computed(() => `/products/v2/products/${toValue(productSetId)}/productSet`),
      method: "GET",
      query: query,
      key: "/products/v2/products/:productSetId/productSet",
      apiName: "Products.productSet",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name ProductSet
   * @summary Details of a product set
   * @request GET:/products/v2/products/{productSetId}/productSet
   */
  $productSet = (
    productSetId: string,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<ProductSetData>({
      path: isMocked("/products/v2/products/:productSetId/productSet")
        ? mockPath("Products", "productSet", `productSetId:${toValue(productSetId)}`)
        : `/products/v2/products/${productSetId}/productSet`,
      method: "GET",
      query: query,
      apiName: "Products.productSet",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name ReviewTranslate
   * @summary Translate review
   * @request POST:/products/v1/review/{reviewId}/translate
   */
  reviewTranslate = <
    ResT = ReviewTranslateData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    reviewId: MaybeRefOrGetter<number>,
    data: ReviewTranslatePayload,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v1/review/:reviewId/translate")
        ? computed(() => mockPath("Products", "reviewTranslate", `reviewId:${toValue(reviewId)}`))
        : computed(() => `/products/v1/review/${toValue(reviewId)}/translate`),
      method: "POST",
      query: query,
      body: data,
      key: "/products/v1/review/:reviewId/translate",
      apiName: "Products.reviewTranslate",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name ReviewTranslate
   * @summary Translate review
   * @request POST:/products/v1/review/{reviewId}/translate
   */
  $reviewTranslate = (
    reviewId: number,
    data: ReviewTranslatePayload,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<ReviewTranslateData>({
      path: isMocked("/products/v1/review/:reviewId/translate")
        ? mockPath("Products", "reviewTranslate", `reviewId:${toValue(reviewId)}`)
        : `/products/v1/review/${reviewId}/translate`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Products.reviewTranslate",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PLP
   * @name Catalog
   * @summary Product info and details
   * @request GET:/products/v2/catalog
   */
  catalog = <ResT = CatalogData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    query: {
      /** Product count to start */
      start: number;
      /** Number of products to return */
      count: number;
      /** Sort order */
      sort: "bestMatches" | "whatsNew" | "priceLowToHigh" | "priceHighToLow" | "rating" | "topSellers";
      /** Category ID */
      cgid: string;
      /** Context */
      context?: string;
      /** Filters in url-encoded stringified JSON */
      filters?: string;
      /**
       * Custom trigger value for search provider
       * @pattern ^.+#.+$
       */
      customTrigger?: string;
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v2/catalog")
        ? computed(() => mockPath("Products", "catalog", ``))
        : computed(() => `/products/v2/catalog`),
      method: "GET",
      query: query,
      key: "/products/v2/catalog",
      apiName: "Products.catalog",
      ...params,
    });
  /**
   * No description
   *
   * @tags PLP
   * @name Catalog
   * @summary Product info and details
   * @request GET:/products/v2/catalog
   */
  $catalog = (
    query: {
      /** Product count to start */
      start: number;
      /** Number of products to return */
      count: number;
      /** Sort order */
      sort: "bestMatches" | "whatsNew" | "priceLowToHigh" | "priceHighToLow" | "rating" | "topSellers";
      /** Category ID */
      cgid: string;
      /** Context */
      context?: string;
      /** Filters in url-encoded stringified JSON */
      filters?: string;
      /**
       * Custom trigger value for search provider
       * @pattern ^.+#.+$
       */
      customTrigger?: string;
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<CatalogData>({
      path: isMocked("/products/v2/catalog") ? mockPath("Products", "catalog", ``) : `/products/v2/catalog`,
      method: "GET",
      query: query,
      apiName: "Products.catalog",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name Notify
   * @summary Product info and details
   * @request PUT:/products/v1/notifications
   */
  notify = <ResT = NotifyData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: NotifyPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v1/notifications")
        ? computed(() => mockPath("Products", "notify", ``))
        : computed(() => `/products/v1/notifications`),
      method: "PUT",
      body: data,
      key: "/products/v1/notifications",
      apiName: "Products.notify",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name Notify
   * @summary Product info and details
   * @request PUT:/products/v1/notifications
   */
  $notify = (data: NotifyPayload, requestParams: ApiOptions = {}) =>
    this.$request<NotifyData>({
      path: isMocked("/products/v1/notifications") ? mockPath("Products", "notify", ``) : `/products/v1/notifications`,
      method: "PUT",
      body: data,
      apiName: "Products.notify",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Search
   * @name Search
   * @summary Product info and details
   * @request GET:/products/v2/search/productSearch
   */
  search = <ResT = SearchData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    query: {
      /** Search query to send */
      q: string;
      /** Product count to start */
      start?: number;
      /** Number of products to return */
      count?: number;
      /** Sort order */
      sort?: "bestMatches" | "whatsNew" | "priceLowToHigh" | "priceHighToLow" | "rating" | "topSellers";
      /** Filters */
      filters?: string;
      /**
       * Custom trigger value for search provider
       * @pattern ^.+#.+$
       */
      customTrigger?: string;
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v2/search/productSearch")
        ? computed(() => mockPath("Products", "search", ``))
        : computed(() => `/products/v2/search/productSearch`),
      method: "GET",
      query: query,
      key: "/products/v2/search/productSearch",
      apiName: "Products.search",
      ...params,
    });
  /**
   * No description
   *
   * @tags Search
   * @name Search
   * @summary Product info and details
   * @request GET:/products/v2/search/productSearch
   */
  $search = (
    query: {
      /** Search query to send */
      q: string;
      /** Product count to start */
      start?: number;
      /** Number of products to return */
      count?: number;
      /** Sort order */
      sort?: "bestMatches" | "whatsNew" | "priceLowToHigh" | "priceHighToLow" | "rating" | "topSellers";
      /** Filters */
      filters?: string;
      /**
       * Custom trigger value for search provider
       * @pattern ^.+#.+$
       */
      customTrigger?: string;
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<SearchData>({
      path: isMocked("/products/v2/search/productSearch")
        ? mockPath("Products", "search", ``)
        : `/products/v2/search/productSearch`,
      method: "GET",
      query: query,
      apiName: "Products.search",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Search
   * @name AutoSuggest
   * @summary Product seach auto-suggestions
   * @request GET:/products/v2/search/autoSuggest
   */
  autoSuggest = <ResT = AutoSuggestData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    query: {
      /** Search term */
      q: string;
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v2/search/autoSuggest")
        ? computed(() => mockPath("Products", "autoSuggest", ``))
        : computed(() => `/products/v2/search/autoSuggest`),
      method: "GET",
      query: query,
      key: "/products/v2/search/autoSuggest",
      apiName: "Products.autoSuggest",
      ...params,
    });
  /**
   * No description
   *
   * @tags Search
   * @name AutoSuggest
   * @summary Product seach auto-suggestions
   * @request GET:/products/v2/search/autoSuggest
   */
  $autoSuggest = (
    query: {
      /** Search term */
      q: string;
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<AutoSuggestData>({
      path: isMocked("/products/v2/search/autoSuggest")
        ? mockPath("Products", "autoSuggest", ``)
        : `/products/v2/search/autoSuggest`,
      method: "GET",
      query: query,
      apiName: "Products.autoSuggest",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name Questions
   * @summary Product questions
   * @request GET:/products/v1/products/{productId}/reviews/questions
   */
  questions = <ResT = QuestionsData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    productId: MaybeRefOrGetter<string>,
    query?: {
      /** The number of Product Reviews you want to return. */
      pagingSize?: number;
      /** The Review where you want to start returning Product Reviews from. If you want reviews from page one, omit this parameter. */
      pagingFrom?: number;
      /** The order in which you want to return your Product Reviews. */
      sort?: string;
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
      /** Search term used to filer elements. */
      q?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v1/products/:productId/reviews/questions")
        ? computed(() => mockPath("Products", "questions", `productId:${toValue(productId)}`))
        : computed(() => `/products/v1/products/${toValue(productId)}/reviews/questions`),
      method: "GET",
      query: query,
      key: "/products/v1/products/:productId/reviews/questions",
      apiName: "Products.questions",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name Questions
   * @summary Product questions
   * @request GET:/products/v1/products/{productId}/reviews/questions
   */
  $questions = (
    productId: string,
    query?: {
      /** The number of Product Reviews you want to return. */
      pagingSize?: number;
      /** The Review where you want to start returning Product Reviews from. If you want reviews from page one, omit this parameter. */
      pagingFrom?: number;
      /** The order in which you want to return your Product Reviews. */
      sort?: string;
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
      /** Search term used to filer elements. */
      q?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<QuestionsData>({
      path: isMocked("/products/v1/products/:productId/reviews/questions")
        ? mockPath("Products", "questions", `productId:${toValue(productId)}`)
        : `/products/v1/products/${productId}/reviews/questions`,
      method: "GET",
      query: query,
      apiName: "Products.questions",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name AskQuestion
   * @summary Ask a question
   * @request POST:/products/v1/products/{productId}/reviews/questions
   */
  askQuestion = <ResT = AskQuestionData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    productId: MaybeRefOrGetter<string>,
    data: ProductReviewsQuestionsAskRequest,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v1/products/:productId/reviews/questions")
        ? computed(() => mockPath("Products", "askQuestion", `productId:${toValue(productId)}`))
        : computed(() => `/products/v1/products/${toValue(productId)}/reviews/questions`),
      method: "POST",
      query: query,
      body: data,
      key: "/products/v1/products/:productId/reviews/questions",
      apiName: "Products.askQuestion",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name AskQuestion
   * @summary Ask a question
   * @request POST:/products/v1/products/{productId}/reviews/questions
   */
  $askQuestion = (
    productId: string,
    data: ProductReviewsQuestionsAskRequest,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<AskQuestionData>({
      path: isMocked("/products/v1/products/:productId/reviews/questions")
        ? mockPath("Products", "askQuestion", `productId:${toValue(productId)}`)
        : `/products/v1/products/${productId}/reviews/questions`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Products.askQuestion",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name AnswerVote
   * @summary Vote for answer
   * @request POST:/products/v1/products/{productId}/review/{answerId}/questions/vote
   */
  answerVote = <ResT = AnswerVoteData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    productId: MaybeRefOrGetter<string>,
    answerId: MaybeRefOrGetter<number>,
    data: ProductAnswersVoteRequest,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v1/products/:productId/review/:answerId/questions/vote")
        ? computed(() =>
            mockPath("Products", "answerVote", `productId:${toValue(productId)}.answerId:${toValue(answerId)}`),
          )
        : computed(() => `/products/v1/products/${toValue(productId)}/review/${toValue(answerId)}/questions/vote`),
      method: "POST",
      query: query,
      body: data,
      key: "/products/v1/products/:productId/review/:answerId/questions/vote",
      apiName: "Products.answerVote",
      ...params,
    });
  /**
   * No description
   *
   * @tags PDP
   * @name AnswerVote
   * @summary Vote for answer
   * @request POST:/products/v1/products/{productId}/review/{answerId}/questions/vote
   */
  $answerVote = (
    productId: string,
    answerId: number,
    data: ProductAnswersVoteRequest,
    query?: {
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<AnswerVoteData>({
      path: isMocked("/products/v1/products/:productId/review/:answerId/questions/vote")
        ? mockPath("Products", "answerVote", `productId:${toValue(productId)}.answerId:${toValue(answerId)}`)
        : `/products/v1/products/${productId}/review/${answerId}/questions/vote`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Products.answerVote",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags PLP, PDP
   * @name ProductWidgetRecommendations
   * @summary Product recommendations widget
   * @request GET:/products/v1/widgetRecommendations
   */
  productWidgetRecommendations = <
    ResT = ProductWidgetRecommendationsData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    query: {
      /** The type of the page the widget will be displayed on */
      ptype: "category" | "product";
      /**
       * The URL of the page the widget will be displayed on
       * @pattern ^https?:\/\/.+
       */
      url: string;
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/products/v1/widgetRecommendations")
        ? computed(() => mockPath("Products", "productWidgetRecommendations", ``))
        : computed(() => `/products/v1/widgetRecommendations`),
      method: "GET",
      query: query,
      key: "/products/v1/widgetRecommendations",
      apiName: "Products.productWidgetRecommendations",
      ...params,
    });
  /**
   * No description
   *
   * @tags PLP, PDP
   * @name ProductWidgetRecommendations
   * @summary Product recommendations widget
   * @request GET:/products/v1/widgetRecommendations
   */
  $productWidgetRecommendations = (
    query: {
      /** The type of the page the widget will be displayed on */
      ptype: "category" | "product";
      /**
       * The URL of the page the widget will be displayed on
       * @pattern ^https?:\/\/.+
       */
      url: string;
      /**
       * Locale used for cache key entry only
       * @pattern ^[a-z]{2}-[a-z]{2}$
       */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<ProductWidgetRecommendationsData>({
      path: isMocked("/products/v1/widgetRecommendations")
        ? mockPath("Products", "productWidgetRecommendations", ``)
        : `/products/v1/widgetRecommendations`,
      method: "GET",
      query: query,
      apiName: "Products.productWidgetRecommendations",
      ...requestParams,
    });
}
