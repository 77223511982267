<template>
  <nav v-style="{ bg: backgroundColor, c: titleColor }" :aria-label="title">
    <div class="flex gap-8 py-3 container <lg:hidden" :class="{ between: alignment === 'right' }">
      <p class="title-3">
        {{ title }}
      </p>
      <ul class="flex" :class="divider ? 'gap-4' : 'gap-8'">
        <li v-for="({ text, url, targetAttribute }, key) in targets" :key>
          <vf-link :target="targetAttribute" :to="url" variant="quiet">
            {{ text }}
          </vf-link>
          <span v-if="divider && key !== targets.length - 1" class="ml-4">{{ divider }}</span>
        </li>
      </ul>
    </div>
    <vf-accordion v-model="opened" class="b-0 lg:hidden" :title>
      <ul>
        <li v-for="({ text, url, targetAttribute }, key) in targets" :key class="b-t b-grey-70 py-2">
          <vf-link class="block" :target="targetAttribute" :to="url" variant="quiet" @click="opened = false">
            {{ text }}
          </vf-link>
        </li>
      </ul>
    </vf-accordion>
  </nav>
</template>

<script setup lang="ts">
import type { SecondaryNavContent } from '#types/components/cms/secondary-nav'

const { content } = defineProps<{
  content: SecondaryNavContent
}>()

const { title, targets, titleColor, backgroundColor, alignment } = content
const { divider } = useAppConfig().components.cms.secondaryNav

const opened = ref(false)
</script>
