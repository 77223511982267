<template>
  <div>
    <div v-if="data">
      <div v-if="content.showFilterBar" class="sticky top-0 z-1 b-y b-grey-70 bg-white px-2 pb-2 pt-4">
        <div class="flex between">
          <h2 class="pr-2 subtitle-2 fw-bold">
            {{ $t.articleFilters }}
          </h2>
          <template v-if="activeFilters.length">
            <div>
              ({{ activeFilters.length }})
            </div>
            <base-button class="mr-a px-2 text-sm underlined" @click="clearAll">
              {{ $t.clearAll }}
            </base-button>
          </template>
          <div>
            <base-button
              class="text-sm underlined md:hidden"
              :class="{ '<md:hidden': expanded && data.facets.length >= filtersPerBreakpoint.sm }"
              @click="expanded = !expanded"
            >
              {{ $t.seeMore }}
            </base-button>
          </div>
        </div>

        <div class="flex py-4" :class="{ wrap: expanded }">
          <client-only>
            <base-button
              v-for="(filter, i) in filtersToShow"
              :key="filter.id"
              class="py-1 pr-2 text-sm"
              :class="{ 'fw-bold underlined': activeFilters.includes(filter.id), 'pl-2': i > 0 }"
              @click="toggleFilter(filter.id)"
            >
              {{ filter.label }}
            </base-button>

            <base-button
              class="px-2 text-sm underlined"
              :class="{
                '<md:hidden': !expanded,
                'md:hidden': data.facets.length <= filtersPerBreakpoint.md,
                'lg:hidden': data.facets.length <= filtersPerBreakpoint.lg,
              }"
              @click="expanded = !expanded"
            >
              {{ expanded ? $t.seeLess : $t.seeMore }}
            </base-button>
          </client-only>
        </div>
      </div>
      <div>
        <h2 class="pb-6 pt-8 text-center title-1">
          {{ content.title }}
        </h2>
      </div>
      <base-grid :cols="content.gridSize" gap="sm">
        <div v-for="article in articlesToShow" :key="article.id">
          <vf-article-card
            v-bind="article"
            :image="{
              src: getMediaUrl(article.picture?.url),
              alt: article.picture.alt,
            }"
            :to="article.url"
            :tags="[article.eyebrow]"
          />
        </div>
      </base-grid>
      <div v-if="content.loadMore && articlesToShow.length < data?.numFound" class="pt-6 text-center">
        <vf-button :loading="pending" @click="loadMore">
          {{ $t.readMore }}
        </vf-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ArticleGridContent } from '#types/components/cms/article-grid'
import type { SearchData } from '#types/product'

const { content } = defineProps<{ content: ArticleGridContent }>()
const { $viewport } = useNuxtApp()
const { getMediaUrl, getSearch } = useCms()

const DEFAULT_ROWS_TO_LOAD = 3

const rowsToLoad = ref(content.rowsToLoad * content.gridSize.sm! || DEFAULT_ROWS_TO_LOAD)
const offset = ref(0)
const expanded = ref(false)

const filtersPerBreakpoint = {
  sm: 4,
  md: 10,
  lg: 20
}

const parseQuery = (query: string): Record<string, string[]> => {
  const urlParams = new URLSearchParams(query)

  return Array.from(urlParams.entries()).reduce((acc, [key, value]) => {
    if (acc[key])
      acc[key] = [...acc[key], value]
    else
      acc[key] = [value]

    return acc
  }, {})
}

const queryParsed = parseQuery(content.initialQuery)
const queryWithoutFiltersParsed = parseQuery(content.initialQueryNotFiltered)

const activeFilters = useRouteQuery<string[]>('tagId', queryParsed.tagId || [], { transform: (param) => [param].flat() })

const query = computed(() => ({
  ...queryParsed,
  offset: offset.value,
  limit: rowsToLoad.value,
  tagId: activeFilters.value
}))

const { data, pending } = useAsyncData('articles', () => getSearch('article', query.value), {
  default: () => ({ result: [], facets: [], numFound: 0 }),
  dedupe: 'defer',
  transform: (input) => {
    const newArticles = input?.result || []
    const prevArticles: typeof newArticles = offset.value !== 0 && data.value?.result ? data.value.result : []
    return { ...input, result: [...prevArticles, ...newArticles] } as SearchData
  },
  watch: [query]
})

const articlesToShow = computed(() => data.value?.result || [])

const filtersToShow = computed(() => {
  return (expanded.value
    ? data.value?.facets
    : data.value?.facets.slice(0, filtersPerBreakpoint[$viewport.breakpoint])) || []
})

const loadMore = () => {
  offset.value += rowsToLoad.value
}

const clearAll = () => {
  activeFilters.value = queryWithoutFiltersParsed.tagId || []
}

const toggleFilter = (filter: string) => {
  if (activeFilters.value.includes(filter))
    activeFilters.value = activeFilters.value.filter((f) => f !== filter)
  else
    activeFilters.value = [...activeFilters.value, filter]
}
</script>
