<template>
  <div >
    <cms-section
      v-for="menuItem in content.children"
      :key="menuItem.id"
      :section="{ name: menuItem.id, items: [{ ...menuItem, isFullWidth: true }] }"
    />
  </div>
</template>

<script lang="ts" setup>
import { MegaMenuMobileContextKey } from './context'
import type { MegaMenuContent } from '#types/components/cms/mega-menu'

const { content } = defineProps<{
  content: MegaMenuContent
}>()

provide(MegaMenuMobileContextKey, true)
</script>
