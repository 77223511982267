/**
 * Capitalizes all string sentence.
 * @param {string} sentence - String to capitalize
 * @returns {string} - string
 * @example
 * capitalize('storefront test')
 * returns 'Storefront Test'
 * @category Utils
 */
export const fullCapitalize = (sentence = '') => sentence
  .split(' ')
  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  .join(' ')
