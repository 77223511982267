import type { ToastProps } from '#types/notification'

export interface Toast { id?: string, props?: ToastProps, autoClose?: boolean, delay?: number }

const toasts = ref<Toast[]>([])

export default createGlobalState(() => {
  const { toasterDismissDelay, ctaToasterDismissDelay } = useAppConfig()
  const INTERVAL = 200
  const _delay = ref(toasterDismissDelay)
  const timers: Record<string, number> = {}

  const { pause, resume } = useIntervalFn(() => {
    Object.keys(timers).forEach((key) => {
      timers[key] -= INTERVAL
      if (timers[key] <= 0)
        remove(key)
    })
  }, INTERVAL)

  function add({ props, autoClose = true, delay }: Toast = {}) {
    const id = `toast_${getIdClient()}`
    if (autoClose) {
      _delay.value = delay || (props?.ctas?.length ? ctaToasterDismissDelay : toasterDismissDelay)
      timers[id] = _delay.value
    }

    toasts.value.push({
      props,
      id
    })
  }

  function remove(id) {
    toasts.value = toasts.value.filter((toast) => toast.id !== id)
    delete timers[id]
  }

  const stopWatch = watchEffect(() => {
    if (!_delay.value) {
      pause()
      nextTick(() => stopWatch())
      return
    }
    if (!toasts.value.length)
      pause()
    else resume()
  })

  return {
    toasts: readonly(toasts),
    add,
    remove,
    pause,
    resume
  }
})
