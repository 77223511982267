<template>
  <div class="space-y-4">
    <h2
      v-if="title"
      v-style:c="titleColor"
      :class="titleStyle"
      data-test-id="cms-icon-and-text-collection-title"
    >
      {{ title }}
    </h2>
    <component
      :is="target ? BaseLink : 'div'"
      v-for="({
        icon: { icon = '', dir = undefined } = {},
        richText,
        target,
      }, i) in iconAndText"
      :key="i"
      :to="target?.url"
      :target="target?.targetAttribute"
      class="flex items-center gap-2 text-sm"
    >
      <vf-icon v-if="icon" :name="icon" :dir />
      <cms-rich-text :content="{ richText, type: 'VfCanvasRichText' }" />
    </component>
  </div>
</template>

<script setup lang="ts">
import { BaseLink } from '#components'
import type { IconAndTextCollectionContent } from '#types/components/cms/icon-and-text-collection'

const { content } = defineProps<{
  content: IconAndTextCollectionContent
}>()
const { title, titleStyle, titleColor, iconAndText } = content
</script>
