/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type {
  ChangePasswordData,
  ChangePasswordPayload,
  ConfirmForgotPasswordData,
  ConfirmForgotPasswordPayload,
  EmployeeSignInData,
  EmployeeSignInPayload,
  ResetPasswordData,
  ResetPasswordPayload,
  SignInData,
  SignInPayload,
  SignOutData,
  SignOutPayload,
  SignUpData,
  SignUpPayload,
  TokenData,
  TokenPayload,
} from "./data-contracts";

export class Authentication extends HttpClient {
  static paths = {
    token: "/token/v2/auth/token",
    signIn: "/consumers-v3/auth/signin",
    signOut: "/consumers-v3/consumer/auth/signout",
    signUp: "/consumers-v3/auth/signup",
    changePassword: "/consumers-v3/consumer/changepassword",
    confirmForgotPassword: "/consumers-v3/confirmforgotpassword",
    resetPassword: "/consumers-v3/consumer/resetpassword",
    employeeSignIn: "/consumers-v3/consumer/agent/login",
  };
  static mockDataPaths = {};
  static mockedPaths = {
    token: false,
    signIn: false,
    signOut: false,
    signUp: false,
    changePassword: false,
    confirmForgotPassword: false,
    resetPassword: false,
    employeeSignIn: false,
  };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "authentication");
  }

  /**
   * No description
   *
   * @tags Authentication
   * @name Token
   * @summary Get Token
   * @request POST:/token/v2/auth/token
   */
  token = <ResT = TokenData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: TokenPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/token/v2/auth/token")
        ? computed(() => mockPath("Authentication", "token", ``))
        : computed(() => `/token/v2/auth/token`),
      method: "POST",
      body: data,
      key: "/token/v2/auth/token",
      apiName: "Authentication.token",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name Token
   * @summary Get Token
   * @request POST:/token/v2/auth/token
   */
  $token = (data: TokenPayload, requestParams: ApiOptions = {}) =>
    this.$request<TokenData>({
      path: isMocked("/token/v2/auth/token") ? mockPath("Authentication", "token", ``) : `/token/v2/auth/token`,
      method: "POST",
      body: data,
      apiName: "Authentication.token",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name SignIn
   * @summary Customer Sign-In
   * @request POST:/consumers-v3/auth/signin
   */
  signIn = <ResT = SignInData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: SignInPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/auth/signin")
        ? computed(() => mockPath("Authentication", "signIn", ``))
        : computed(() => `/consumers-v3/auth/signin`),
      method: "POST",
      body: data,
      key: "/consumers-v3/auth/signin",
      apiName: "Authentication.signIn",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name SignIn
   * @summary Customer Sign-In
   * @request POST:/consumers-v3/auth/signin
   */
  $signIn = (data: SignInPayload, requestParams: ApiOptions = {}) =>
    this.$request<SignInData>({
      path: isMocked("/consumers-v3/auth/signin")
        ? mockPath("Authentication", "signIn", ``)
        : `/consumers-v3/auth/signin`,
      method: "POST",
      body: data,
      apiName: "Authentication.signIn",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name SignOut
   * @summary Customer Sign-Out
   * @request POST:/consumers-v3/consumer/auth/signout
   */
  signOut = <ResT = SignOutData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: SignOutPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/auth/signout")
        ? computed(() => mockPath("Authentication", "signOut", ``))
        : computed(() => `/consumers-v3/consumer/auth/signout`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/auth/signout",
      apiName: "Authentication.signOut",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name SignOut
   * @summary Customer Sign-Out
   * @request POST:/consumers-v3/consumer/auth/signout
   */
  $signOut = (data: SignOutPayload, requestParams: ApiOptions = {}) =>
    this.$request<SignOutData>({
      path: isMocked("/consumers-v3/consumer/auth/signout")
        ? mockPath("Authentication", "signOut", ``)
        : `/consumers-v3/consumer/auth/signout`,
      method: "POST",
      body: data,
      apiName: "Authentication.signOut",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name SignUp
   * @summary Customer Sign-Up
   * @request POST:/consumers-v3/auth/signup
   */
  signUp = <ResT = SignUpData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    data: SignUpPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/auth/signup")
        ? computed(() => mockPath("Authentication", "signUp", ``))
        : computed(() => `/consumers-v3/auth/signup`),
      method: "POST",
      body: data,
      key: "/consumers-v3/auth/signup",
      apiName: "Authentication.signUp",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name SignUp
   * @summary Customer Sign-Up
   * @request POST:/consumers-v3/auth/signup
   */
  $signUp = (data: SignUpPayload, requestParams: ApiOptions = {}) =>
    this.$request<SignUpData>({
      path: isMocked("/consumers-v3/auth/signup")
        ? mockPath("Authentication", "signUp", ``)
        : `/consumers-v3/auth/signup`,
      method: "POST",
      body: data,
      apiName: "Authentication.signUp",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name ChangePassword
   * @summary Change Password
   * @request POST:/consumers-v3/consumer/changepassword
   */
  changePassword = <
    ResT = ChangePasswordData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: ChangePasswordPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/changepassword")
        ? computed(() => mockPath("Authentication", "changePassword", ``))
        : computed(() => `/consumers-v3/consumer/changepassword`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/changepassword",
      apiName: "Authentication.changePassword",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name ChangePassword
   * @summary Change Password
   * @request POST:/consumers-v3/consumer/changepassword
   */
  $changePassword = (data: ChangePasswordPayload, requestParams: ApiOptions = {}) =>
    this.$request<ChangePasswordData>({
      path: isMocked("/consumers-v3/consumer/changepassword")
        ? mockPath("Authentication", "changePassword", ``)
        : `/consumers-v3/consumer/changepassword`,
      method: "POST",
      body: data,
      apiName: "Authentication.changePassword",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name ConfirmForgotPassword
   * @summary Confirm Forgot Password
   * @request POST:/consumers-v3/confirmforgotpassword
   */
  confirmForgotPassword = <
    ResT = ConfirmForgotPasswordData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: ConfirmForgotPasswordPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/confirmforgotpassword")
        ? computed(() => mockPath("Authentication", "confirmForgotPassword", ``))
        : computed(() => `/consumers-v3/confirmforgotpassword`),
      method: "POST",
      body: data,
      key: "/consumers-v3/confirmforgotpassword",
      apiName: "Authentication.confirmForgotPassword",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name ConfirmForgotPassword
   * @summary Confirm Forgot Password
   * @request POST:/consumers-v3/confirmforgotpassword
   */
  $confirmForgotPassword = (data: ConfirmForgotPasswordPayload, requestParams: ApiOptions = {}) =>
    this.$request<ConfirmForgotPasswordData>({
      path: isMocked("/consumers-v3/confirmforgotpassword")
        ? mockPath("Authentication", "confirmForgotPassword", ``)
        : `/consumers-v3/confirmforgotpassword`,
      method: "POST",
      body: data,
      apiName: "Authentication.confirmForgotPassword",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name ResetPassword
   * @summary Reset Password
   * @request POST:/consumers-v3/consumer/resetpassword
   */
  resetPassword = <
    ResT = ResetPasswordData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: ResetPasswordPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/resetpassword")
        ? computed(() => mockPath("Authentication", "resetPassword", ``))
        : computed(() => `/consumers-v3/consumer/resetpassword`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/resetpassword",
      apiName: "Authentication.resetPassword",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name ResetPassword
   * @summary Reset Password
   * @request POST:/consumers-v3/consumer/resetpassword
   */
  $resetPassword = (data: ResetPasswordPayload, requestParams: ApiOptions = {}) =>
    this.$request<ResetPasswordData>({
      path: isMocked("/consumers-v3/consumer/resetpassword")
        ? mockPath("Authentication", "resetPassword", ``)
        : `/consumers-v3/consumer/resetpassword`,
      method: "POST",
      body: data,
      apiName: "Authentication.resetPassword",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name EmployeeSignIn
   * @summary Employee Sign In
   * @request POST:/consumers-v3/consumer/agent/login
   */
  employeeSignIn = <
    ResT = EmployeeSignInData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: EmployeeSignInPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/agent/login")
        ? computed(() => mockPath("Authentication", "employeeSignIn", ``))
        : computed(() => `/consumers-v3/consumer/agent/login`),
      method: "POST",
      body: data,
      key: "/consumers-v3/consumer/agent/login",
      apiName: "Authentication.employeeSignIn",
      ...params,
    });
  /**
   * No description
   *
   * @tags Authentication
   * @name EmployeeSignIn
   * @summary Employee Sign In
   * @request POST:/consumers-v3/consumer/agent/login
   */
  $employeeSignIn = (data: EmployeeSignInPayload, requestParams: ApiOptions = {}) =>
    this.$request<EmployeeSignInData>({
      path: isMocked("/consumers-v3/consumer/agent/login")
        ? mockPath("Authentication", "employeeSignIn", ``)
        : `/consumers-v3/consumer/agent/login`,
      method: "POST",
      body: data,
      apiName: "Authentication.employeeSignIn",
      ...requestParams,
    });
}
