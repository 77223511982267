import type { InlineGridSection } from '#types/components/product/list'
import type { ContentPromotionTracking } from '#types/content'
import type { Section } from '#types/page'

export default (
  promotionTracking?: ContentPromotionTracking,
  section = {} as Section | InlineGridSection,
  contentName = ''
) => {
  const { $gtm, $viewport } = useNuxtApp()

  const elementRef = ref<HTMLElement>()
  const isVisible = useElementVisibility(elementRef)

  const position = computed(() => {
    const _position = 'position' in section ? `pos${section.position[$viewport.breakpoint]}` : null
    return [section.name, _position, contentName].join('_')
  })

  const handlePromotionClick = () => {
    if (promotionTracking) {
      $gtm.push('promotion.onPromotionClick', {
        creative: promotionTracking.creativeName,
        id: promotionTracking.promotionId,
        name: promotionTracking.promotionName,
        position: position.value
      })
    }
  }

  const handlePromotionImpression = () => {
    if (promotionTracking) {
      $gtm.push('promotion.onPromotionImpressions', {
        creative: promotionTracking.creativeName,
        id: promotionTracking.promotionId,
        name: promotionTracking.promotionName,
        position: position.value
      })
    }
  }

  if (promotionTracking)
    watchOnce(isVisible, handlePromotionImpression)

  return {
    elementRef,
    isVisible,
    handlePromotionClick,
    handlePromotionImpression
  }
}
