<template>
  <component
    :is="mediaTarget ? BaseLink : 'div'"
    ref="elementRef"
    :to="mediaTarget?.url"
    :target="mediaTarget?.targetAttribute"
    class="relative block h-full"
    @click="handlePromotionClick"
  >
    <base-picture
      v-if="responsiveMedia.images"
      v-bind="responsiveMedia.images"
      :lazy
      fit="cover"
      class="full"
    />
    <div
      :class="{
        'absolute-0 px-2 py-6': variant === 'text-overlay',
        'py-2': variant === 'text-below',
      }"
    >
      <div
        class="h-full f-col"
        :class="[
          position[overlayPosition],
          { 'text-center': alignment === 'center' },
        ]"
      >
        <div v-style:bg="backdrop" :class="{ 'p-2': backdrop }">
          <h2
            v-if="title"
            v-style:c="titleColor"
            :class="titleStyle"
            class="mb-1"
            data-test-id="cms-banner-title"
          >
            {{ title }}
          </h2>
          <p
            v-if="subtitle"
            v-style:c="subtitleColor"
            :class="subtitleStyle"
            data-test-id="cms-banner-text"
          >
            {{ subtitle }}
          </p>
        </div>

        <div>
          <cms-shared-button v-if="target" v-bind="target" class="mt-4" :size />
        </div>
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import { BaseLink } from '#components'
import { SectionContextKey } from '#content/components/cms/section/context'

import type { BannerMenuContent } from '#types/components/cms/banner-menu'
import type { ButtonSize } from '#types/components/vf/button'

const { content } = defineProps<{
  content: BannerMenuContent
}>()

const {
  alignment,
  backdrop,
  overlayPosition,
  mediaTarget,
  mediaObject,
  promotionTracking,
  subtitle,
  subtitleColor,
  subtitleStyle,
  target,
  title,
  titleColor,
  titleStyle,
  variant
} = content

const { getMedia } = useCms()
const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)

const lazy = inject(LazyMedia)
const responsiveMedia = getMedia({ sm: mediaObject })
const size = { sm: { size: 'tiny' as ButtonSize } }

const position: Partial<Record<BannerMenuContent['overlayPosition'], string>> = {
  'overlay-center': 'justify-center',
  'overlay-bottom': 'justify-end',
}
</script>
