import type { InjectionKey } from 'vue'

interface MegaMenuL2Context {
  title: string
  /**
   * Featured L2 items are propagated to L3 level and highlight them as well
   */
  featured: boolean
}

export const MegaMenuL1ContextKey: InjectionKey<string> = Symbol('MegaMenuL1Context')
export const MegaMenuL2ContextKey: InjectionKey<MegaMenuL2Context> = Symbol('MegaMenuL2Context')
export const MegaMenuMobileContextKey: InjectionKey<boolean> = Symbol('MegaMenuMobileContext')
