<template>
  <div
    :data-crl8-container-id="containerId"
    :data-crl8-filter="filterValue"
    :data-crl8-locale="$locale"
    data-crl8-auto-init="false"
  />
</template>

<script lang="ts" setup>
import type { UGCContent } from '#types/components/cms/ugc'

const { content } = defineProps<{ content: UGCContent }>()
const { containerId, filter } = content
const { $crl8 } = useNuxtApp()
const route = useRoute()

const categoryId = getUrlId(route.path, 'Category')
const productId = getUrlId(route.path, 'Product')

const filterValue = filter?.replace('PRODUCT_ID', productId)
  .replace('CATEGORY', categoryId)

onMounted(() => $crl8.create(containerId))
onBeforeUnmount(() => $crl8.destroy(containerId))
</script>
