import { useRouteQuery as _useRouteQuery } from '@vueuse/router'

type ReactiveRouteOptionsWithTransform = Parameters<typeof _useRouteQuery>[2]
/**
 * Extends the original `useRouteQuery` to use the Nuxt's route and router
 */
export function useRouteQuery(name: string): Ref<null | string | string[]>
export function useRouteQuery<T extends null | undefined | string | string[] = null | string | string[]>(
  name: string,
  defaultValue?: T,
  options?: ReactiveRouteOptionsWithTransform): Ref<T>
export function useRouteQuery<T extends string | string[]>(
  name: string,
  defaultValue?: T,
  options: Omit<ReactiveRouteOptionsWithTransform, 'route' | 'router'> = {}
) {
  return _useRouteQuery<T>(name, defaultValue, {
    ...options,
    route: useRoute(),
    router: useRouter()
  })
}
