import { injectHead } from '@unhead/vue'
import type { CartItem, PriceCart } from '#types/cart'
import type { InteractionOrigin, MonetateImpressionContext, MonetateProductObject, ProductContext } from '#types/gtm'
import type { ProductAttribute } from '#types/product'
import type { MonetateRecommendedProduct } from '#types/monetate'
import type { DetailsData, ProductCardExtended } from '#root/api/clients/product/data-contracts'

const gtmCartProductsMap = useLocalStorage('gtmCartProductsMap', {}, { mergeDefaults: true }).value
const gtmInteractionOrigin = useLocalStorage('gtmInteractionOrigin', '', { mergeDefaults: true })

export const interactionOriginMap = {
  'search': 'Search',
  'grid': 'Grid',
  'jacket-finder': 'Jacket Finder',
  'backpack-finder': 'Backpack Finder',
  'footwear-finder': 'Footwear Finder',
  'explore-collection': 'Explore Collection',
  'product-bundles': 'Product Bundles',
  'shoe-finder-retail': 'Shoe Finder Retail',
  'product-compare': 'Product Compare',
  'tent-sleepingbag-guide': 'Tent and Sleepingbag Guide',
  'direct-to-pdp': 'Direct to PDP'
}

export const getPageBreadcrumb = (crumbs) => {
  return crumbs?.map(({ label }) => label) || undefined
}

export const serializeAnalyticsBreadcrumbs = (crumbs) => crumbs.map(({ label }) => label).join(',')

export const deserializeAnalyticsBreadcrumbs = (serializedCrumbs) => serializedCrumbs?.split(',').map((label) => ({ label }))

export const getPageTitle = async () => {
  const titleTag = (await injectHead()?.resolveTags())?.find(({ tag }) => tag === 'title')
  return titleTag?.textContent || ''
}

/**
 * Many values needed for analytics are not available immidiately on page load.
 * Here we wait for the values to be available before resolving.
 */
export const getUserEventData = async (overrideEmail?: string) => {
  const profile = useProfileStore()
  const auth = useAuthStore()
  let memberId = auth.memberId
  try {
    if (!memberId) memberId = await auth.getMemberId()
  }
  catch (err) {
    log.error(`[@domains/commerce/utils/gtm.ts] Error: ${err}`)
  }

  if (auth.loggedIn && !profile.details) await profile.get()
  const email = overrideEmail || profile.details?.email

  return {
    ...(memberId && { 'loyaltyMember-id': memberId }),
    ...(email && {
      'encoded-email': btoa(email),
      'encoded-email-sha': await generateHashHex(email)
    })
  }
}

const getColorAttributes = (product: CartItem) => {
  const defaultColor = getDefaultColorOption(product.attributes, product.id)
  const colorId = (defaultColor?.id || product.masterId)?.slice(-3)

  return {
    colorId,
    colorLabel: defaultColor?.label || product.colorDescription,
    id: defaultColor?.id
  }
}

export const getListDescription = ({ breadcrumbs, searchTerm = '', appSource = '' }): string => {
  const interactionOrigin = appSource || gtmInteractionOrigin.value || 'direct-to-pdp'

  const isProductList = ['grid', 'search'].includes(interactionOrigin)
  const interactionTitle = interactionOriginMap[interactionOrigin]

  return `${interactionTitle}${isProductList ? `: PLP | ${searchTerm || breadcrumbs}` : ''}`
}

export const getRecommendationListDescription = ({
  list,
  title,
}: {
  list?: string
  title?: string
}): string | undefined => {
  if (list && title) return `${list}: ${title}`

  return list
}

const getAvailableSizes = (attributes: ProductAttribute[]) => {
  const options = attributes?.find((attribute) => attribute.label === 'Size')?.options
  return options?.filter((size) => size.available).map((size) => size.label) || []
}

const getSelectedVariants = (attributes) => {
  let counter = 0
  return (Array.isArray(attributes) ? attributes : Object.values(attributes))
    .reduce((obj, { code, value }) => {
      if (code !== 'color')
        obj[`sizeCode${++counter}`] = value
      return obj
    }, { sizeCode1: '', sizeCode2: '', sizeCode3: '' })
}

const getDiscountTypesByPrice = (price: PriceCart): string | undefined => {
  const discountTypes: string[] = []
  const current = price.highCurrent || price.current
  const original = price.highOriginal || price.original

  current === 0 && discountTypes.push('Free Gift')
  current < original && discountTypes.push('Marked Down')
  return discountTypes.length ? discountTypes.join(',') : undefined
}

// Add persistent storage to cart items to save context attributes needed for analytics
export const setGtmCartProductsMap = (id: string, product) => {
  const persistantValues = {
    productId: product.id,
    avgRating: product.rating?.score || 0,
    availSizes: getAvailableSizes(product.attributes),
    badge: product.badge?.label,
    breadcrumbs: deserializeAnalyticsBreadcrumbs(history.state.breadcrumbs),
    historicalCategory: history.state.category,
    productBreadcrumbs: product.breadcrumbs,
    numReviews: product.rating?.reviewsCount || 0,
    searchTerm: history.state.searchTerm,
    interactionOrigin: gtmInteractionOrigin.value
  }

  gtmCartProductsMap[id] ??= persistantValues
  gtmCartProductsMap[product.id] ??= persistantValues
}
export const removeGtmCartProductsMap = (id: string, productId: string) => {
  delete gtmCartProductsMap[id]
  if (Object.values(gtmCartProductsMap).filter((gtmProduct: any) => gtmProduct.productId === productId).length === 1)
    delete gtmCartProductsMap[productId]
}

export const setInteractionOrigin = (interaction: InteractionOrigin = 'direct-to-pdp') => {
  gtmInteractionOrigin.value = (history?.state.searchTerm || history?.state.breadcrumbs)
    ? history.state.searchTerm ? 'search' : 'grid'
    : interaction
}
export const resetInteractionOrigin = () => (gtmInteractionOrigin.value = '')

/*
  Due to specific financial reporting needs, "price" shall reflect prorated
  amounts which includes cart-level discounts. All other attributes shall
  only reflect product-level pricing and discounts (GLOBAL15-56246)
*/
const getCartProductObject = (product, context) => ({
  onSale: product.price.current !== product.price.original,
  originalPrice: +product.price.original,
  price: +(product.price.priceWithoutTaxPerUnit || product.price.proratedPrice || product.price.current),
  saleDiscountAmount: +(product.price.original - product.price.current).toFixed(2),
  quantity: context.quantity || product.qty,
  virtualStyleCode: product.masterId || product.id,
  vf_itemID: product.productId,
  taxTotal: product.taxTotal,
  ...getSelectedVariants(product.variants)
})

const getProductObject = (product, context) => ({
  avgRating: product.rating?.score || 0,
  availSizes: getAvailableSizes(product.attributes),
  numReviews: product.rating?.reviewsCount || 0,
  onSale: product.price.highCurrent !== product.price.highOriginal,
  originalPrice: +product.price.highOriginal,
  price: +product.price.highCurrent,
  saleDiscountAmount: +(product.price.highOriginal - product.price.highCurrent).toFixed(2),
  searchTerm: context.searchTerm
})

const getProductBrand = (product) => {
  const countryCode = useCountryCode()
  const { brand, isOtw } = useRuntimeConfig().public

  return `${isOtw && product.salesChannel === 'Mainline' ? 'vans' : brand}-${countryCode}`.toUpperCase()
}

export const getProductObjectList
  = (products: CartItem[] | ProductCardExtended[] | DetailsData[], context: ProductContext) => {
    return products?.map((product, i) => {
      const pid = product.productId || product.id
      const isStyle = PRODUCT_STYLE_ID_REGEX.test(pid)
      const colorAttributes = getColorAttributes(product)

      const pageBreadcrumbs = getPageBreadcrumb(context?.breadcrumbs || gtmCartProductsMap[pid]?.breadcrumbs || []).join(' - ')
      const productBreadcrumbs
        = getPageBreadcrumb(product.breadcrumbs || gtmCartProductsMap[pid]?.productBreadcrumbs) || []

      const searchTerm = context.searchTerm || gtmCartProductsMap[pid]?.searchTerm

      const catalogCategory = productBreadcrumbs?.at(-1)
      const category = pageBreadcrumbs || gtmCartProductsMap[pid]?.historicalCategory || context.category || 'Direct to PDP'

      return {
        badge: product.badge?.label,
        bundleId: undefined,
        brand: getProductBrand(product),
        colorCode: colorAttributes?.colorId,
        colorDescription: colorAttributes?.colorLabel,
        coupon: product.productPromotions?.map(({ couponCode, promotionId }) => couponCode || promotionId).join('|'),
        couponDiscountAmount: product.productPromotions?.reduce((acc, { price }) => acc + Math.abs(price), 0),
        couponType: product.productPromotions?.map(({ couponCode }) => couponCode ? 'CODE' : 'AUTO').join('|'),
        onCoupon: !!product.productPromotions,
        discountType: getDiscountTypesByPrice(product.price),
        id: isStyle ? colorAttributes?.id : product.masterId ?? product.id,
        masterId: product.masterId ?? product.styleId?.toUpperCase(),
        merchProductId: product.merchantProductId ?? product.id,
        name: product.name.trim(),
        styleCode: (product.masterId ?? product.id)?.match(useAppConfig().components.gtm.styleId)?.[1],
        list: context.list || getListDescription({
          breadcrumbs: pageBreadcrumbs,
          searchTerm,
          appSource: gtmCartProductsMap[pid]?.interactionOrigin
        }),
        category,
        catalogCategory,
        searchTerm,
        ...(!context.omitPosition ? { position: (context.offset || 0) + i + 1 } : {}),
        ...(context.isCartItem
          ? getCartProductObject(product, context)
          : getProductObject(product, context)),
        ...gtmCartProductsMap[pid]
      }
    }) || []
  }

export const getMonetateProductObjectList = (
  products: MonetateRecommendedProduct[],
  context?: Partial<MonetateImpressionContext>
): MonetateProductObject[] => products.map((product, i) => ({
  id: product.itemGroupId,
  name: product.title,
  avgRating: Number.parseFloat(product.stars || '0'),
  brand: getProductBrand(product), // Will be inaccurate for mainline products on OTW, sales channel not available in Monetate data
  colorCode: product.id.split(':')[2], // That's the best we can do with Monetate data
  list: getRecommendationListDescription({ list: context?.list, title: context?.title }),
  onSale: product.price !== product.salePrice,
  originalPrice: product.price,
  position: i + 1,
  price: product.salePrice,
  saleDiscountAmount: product.price - product.salePrice,
  ...(context?.experience
    ? {
        experience_id: context.experience.id,
        experience_label: context.experience.label,
        experience_name: context.experience.name,
        recs_set: context.experience.component,
        recs_set_id: context.experience.actionId
      }
    : {})
}))
