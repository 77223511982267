/**
 * Finds nested object entry by value/callback.
 * @param obj - target object
 * @param val - value to find or callback function which takes "key" and "value" params and returns boolean value
 * @returns - array in format: [key, value]
 * @example
 * findObjectEntry({ level1: { level2: 'test' }}, 'test')
 * returns ['level2', 'test']
 * @category Utils
 */
export const findObjectEntry = (
  obj: object,
  val: string | number | ((key: string, value: any) => boolean)
): [string, any] | null => {
  if (typeof obj === 'object') {
    for (const key in obj) {
      const value = obj[key]
      if (typeof val === 'function' ? val(key, value) : value === val) {
        return [key, value]
      }
      else {
        const result = findObjectEntry(value, val)
        if (result !== null) return result
      }
    }
  }

  return null
}
