/**
 * This function encodes an ArrayBuffer to a base64URL string
 * @param buffer The ArrayBuffer to encode
 * @returns The base64URL encoded string
 * @example
 * const buffer = new Uint8Array([1, 2, 3, 4, 5]).buffer;
 * const encodedString = base64URLEncode(buffer);
 * console.log(encodedString); // "AQIDBAU"
 */
export const base64URLEncode = (buffer: ArrayBuffer) =>
  btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(buffer))))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')

/**
 * This function generates an array of random bytes with a specified length
 * @param length The length of the array to generate
 * @returns The generated array of random bytes
 * @example
 * const randomBytes = generateRandomBytes(16);
 * console.log(randomBytes); // Uint8Array(16)[...]
 */
export const generateRandomBytes = (length: number) => window.crypto.getRandomValues(new Uint8Array(length))

/**
 * This function generates a SHA-256 hash of a string and returns it as an ArrayBuffer
 * @param buffer The string to hash
 * @returns The SHA-256 hash of the string as an ArrayBuffer
 * @example
 * const hash = await sha256('myString');
 * console.log(hash); // ArrayBuffer {...}
 */
export const sha256 = async (buffer: string) => window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(buffer))

/**
 * This function decodes a base64 string to a UTF-8 string
 * @param inputBase64 The base64 string to decode
 * @returns The decoded UTF-8 string
 * @example
 * const decodedString = base64ToUtf8('dGVzdA==');
 * console.log(decodedString); // "test"
 */
export const base64ToUtf8 = (inputBase64: string) => new TextDecoder('utf-8').decode(new Uint8Array([...atob(inputBase64)].map((c) => c.charCodeAt(0))))

/**
 * This function encodes a string to a UTF-8 and generates a SHA-256 hash of it
 * @param input The string to encode and hash
 * @returns The SHA-256 hash of the encoded string
 * @example
 * const hash = await generateHashHex('myString');
 * console.log(hash); // '906fdfc2a07be...'
 */
export const generateHashHex = async (input: string) => {
  const utf8 = new TextEncoder().encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')
}
