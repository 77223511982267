<template>
  <div
    :id="content.anchorName"
    :data-test-id="`cms-anchor-${content.id}`"
    :style="{
      scrollMarginTop: '4rem',
    }"
  />
</template>

<script lang="ts" setup>
import type { AnchorContent } from '#types/components/cms/anchor'

defineProps<{ content: AnchorContent }>()

useHead({ htmlAttrs: { class: 'motion-safe:scroll-smooth' } })
</script>
