<template>
  <component :is="`lazy-cms-${component?.component}`" class="h-full" :content="component" />
</template>

<script setup lang="ts">
import mappings from '#content/mappings'
import type { InlineGridItem } from '#types/components/product/list'

const props = defineProps<{ content: InlineGridItem }>()
const component = resolveContentComponent(props.content.component, mappings)
</script>
