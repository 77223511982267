import type { Items } from '#types/components/checkout/order'

/**
 * returns given items grouped by shipment id code
 * @param {Items} items - User Locale
 * @category Utils
 */

export const groupItemsByShipment = (items: Items[]) => (code: string) =>
  items.filter(({ shipmentId }) => shipmentId === code)
