<template>
  <div class="relative">
    <base-picture
      v-if="responsiveMedia.images"
      v-bind="responsiveMedia.images"
      :lazy
      fit="cover"
      class="w-full"
    />
    <div class="absolute-0 p-4" :class="{ 'text-center': alignment === 'center' }">
      <p v-if="title" class="mb-4 subtitle-3 c-white" data-test-id="cms-linked-card-title" :class="titleStyle">
        <base-link v-if="titleTarget" :to="titleTarget.url">
          {{ title }}
        </base-link>
        <span v-else>
          {{ title }}
        </span>
      </p>
      <ul v-if="targets.length" class="space-y-2 md:space-y-4" :class="{ 'ml-4': alignment !== 'center' }">
        <li v-for="({ text, url }, i) in targets" :key="i">
          <base-link :to="url" class="c-white underlined hover:c-grey-80">
            {{ text }}
          </base-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MultiLinkCardContent } from '#types/components/cms/multi-link-card'
import type { Target } from '#types/target'

const { content } = defineProps<{
  content: MultiLinkCardContent
}>()

const {
  alignment,
  media,
  targets = [] as Target[],
  title,
  titleStyle,
  titleTarget
} = content

const { getMedia } = useCms()

const lazy = inject(LazyMedia)
const responsiveMedia = getMedia(media)
</script>
