import type { AccountAddress } from '#types/components/account/address'
import type { ShippingAddress } from '#types/cart'

/**
 * Convert Account Address to Shipping Address
 * @param address - Account Address
 * @returns Shipping Address
 */
export const convertToShippingAddress = (address?: AccountAddress): ShippingAddress => {
  if (address) {
    const countryCode = address.country || address.countryCode
    return {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2 ?? '',
      city: address.city,
      country: countryCode,
      countryCode,
      email: address.recipientContactEmail,
      firstName: address.recipientFirstName,
      lastName: address.recipientLastName,
      phone: address.recipientContactPhone,
      phoneCode: '',
      postalCode: address.postalCode,
      province: address.stateCityProvDept,
      shippingId: '',
      addressId: address.id,
      subscriptions: {
        newsletterConsent: false
      }
    }
  }

  return {
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: useCountryCode(),
    countryCode: useCountryCode() || '',
    email: '',
    firstName: '',
    isValidated: false,
    lastName: '',
    phone: '',
    phoneCode: '',
    postalCode: '',
    province: undefined,
    shippingId: '',
    addressId: '',
    subscriptions: {
      newsletterConsent: false
    }
  }
}
