import type { Property } from 'csstype'
import type { Responsive } from '#types/common'

/**
 * Generates visibility styles based on the provided configuration and display property.
 *
 * @param config - The partial responsive boolean configuration. (Optional)
 * @param display - The display property value. Defaults to 'block'.
 * @returns The visibility styles based on the provided configuration and display property.
 *  @category Utils
 */
export const getVisibilityStyles = (config: Partial<Responsive<boolean>> = {}, display: Property.Display = 'block') => {
  if (!Object.values(config).some(Boolean)) return {}

  return Object.keys(useAppConfig().ds.breakpoints)
    .reduce((acc, br) => ({ ...acc, [br]: config[br] ? 'none' : display }), {})
}
