<template>
  <div :class="brandClasses.root">
    <vf-link
      v-if="content.title"
      :to="content.target?.url"
      :target="content.target?.targetAttribute"
      :class="[brandClasses.title, presets[content.menuStyle!]]"
      class="i-block"
      variant="quieter"
      @click="$emit('click', `${l1} - ${content.title}`)"
    >
      {{ content.title }}
    </vf-link>
    <ul v-if="content.children?.length" class="text-sm" :class="brandClasses.ul">
      <li v-for="menuItem in content.children" :key="menuItem.id">
        <cms-section
          :section="{
            name: menuItem.id,
            items: [{ ...menuItem, isFullWidth: true } as MenuItem],
          }"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { MegaMenuL1ContextKey } from './context'
import type { MenuItem } from '#types/components/cms/mega-menu'

defineProps<{
  content: MenuItem
}>()

defineEmits<{
  click: [label: string]
}>()

const { brandClasses, presets } = useAppConfig().components.cms.megaMenuL2Desktop

const l1 = inject(MegaMenuL1ContextKey)
</script>
