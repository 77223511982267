import type { ColorOption, ProductAttribute } from '#types/product'

/**
 * Returns the color attribute object of the default color, or color matching the products
 *
 * @param {ProductAttribute[]} attributes - The array of attriebutes of an item
 * @param {string} id - The ID of the product
 * @example
 * getDefaultColorOption(product.attributes, product.id)
 * returns {
 *   defaultColor: true
 *   id: "NF0A3FJX11P"
 *   label: "Vintage White"
 *   ...
 *   }
 */
export const getDefaultColorOption = (attributes: ProductAttribute[], id: string): ColorOption => {
  const colorAttribute = attributes?.find(({ type }) => type.toLowerCase() === 'color')
  const isStyle = PRODUCT_STYLE_ID_REGEX.test(id)

  return isStyle
    ? colorAttribute?.options.find(({ defaultColor }) => defaultColor)
    : colorAttribute?.options.find(({ id: productId }) => productId === id)
}
