<template>
  <component
    :is="isMegaMenuMobile ? LazyCmsMegaMenuL2Mobile : LazyCmsMegaMenuL2Desktop"
    v-bind="{ content }"
    @click="handleClick"
    @expand="handleClickCollapse($event as string)"
  />
</template>

<script lang="ts" setup>
import { MegaMenuL2ContextKey, MegaMenuMobileContextKey } from './context'
import { LazyCmsMegaMenuL2Desktop, LazyCmsMegaMenuL2Mobile } from '#components'
import type { MenuItem } from '#types/components/cms/mega-menu'

const props = defineProps<{
  content: MenuItem
}>()

const { $gtm } = useNuxtApp()

provide(MegaMenuL2ContextKey, {
  title: props.content.title,
  featured: props.content.menuStyle === 'featured',
})

const isMegaMenuMobile = inject(MegaMenuMobileContextKey, false)

const handleClick = (label: string) => $gtm.push('topNav.onClickTopNavLink', fullCapitalize(label))
const handleClickCollapse = (label: string) => $gtm.push('topNav.onClickMobileMenuSection', fullCapitalize(label))
</script>
