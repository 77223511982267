import type { PopUpContent } from '#types/components/cms/pop-up'
import type { Category } from '#commerce/gtm/popUp'

export default createGlobalState(() => {
  const { $gtm } = useNuxtApp()
  const dismissed = useLocalStorage<string[]>('dismissedPopups', [])

  const popUp = ref<PopUpContent | null>(null)

  const open = (popUps: PopUpContent[], category: Category, action?: string): void => {
    popUp.value = popUps?.filter(({ id }) => !dismissed.value.includes(id))[0]
    $gtm.push('popUp.onExpand', category, action)
  }

  const close = (category: Category, action?: string): void => {
    dismissed.value = [...dismissed.value, popUp.value!.id]
    popUp.value = null
    $gtm.push('popUp.onClose', category, action)
  }

  return {
    close,
    open,
    popUp
  }
})
