<template>
  <vf-button
    :class="{ 'w-full': size?.sm?.fullWidth }"
    :size="buttonSize"
    :variant="style"
    :to="fullUrl"
    :target="targetAttribute"
    :class-content="[{ 'flex-row-reverse': iconPosition === 'right' }, 'gap-1']"
    :aria-label="iconOnly ? text : undefined"
  >
    <vf-icon v-if="icon" :name="icon.icon" size="md" :dir="icon.dir" />
    <template v-if="!iconOnly">
      {{ text }}
    </template>
  </vf-button>
</template>

<script lang="ts" setup>
import type { ButtonSize } from '#types/components/vf/button'
import type { Responsive } from '#types/common'
import type { Target } from '#types/target'

const props = defineProps<Target>()

const {
  size = {} as Responsive<{ size?: ButtonSize, fullWidth?: boolean }>,
  style,
  text,
  url = '',
  icon,
  targetAttribute,
  iconPosition
} = props

const route = useRoute()
const router = useRouter()

const fullUrl = (() => {
  if (url.startsWith('#modal')) {
    return `${route.fullPath.toString()}${url}`
  }
  else if (url.startsWith('?') && url.includes('#modal')) {
    const [urlQuery, urlHash] = url.substring(1).split('#')
    const additionalParams = Object.fromEntries((new URLSearchParams(urlQuery)).entries())
    return router.resolve({
      query: { ...route.query, ...additionalParams },
      hash: urlHash ? `#${urlHash}` : ''
    }).href
  }
  return url
})()

const { sm: { size: sm = 'sm' } = {}, md, lg } = size

const buttonSize: ButtonSize | Responsive<ButtonSize> = {
  sm,
  md: md?.size,
  lg: lg?.size
}
</script>
