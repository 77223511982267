import type { SwappedObject } from '#types/common'

export const swapObjectKeys = <T extends Record<string, any>>(obj: T): SwappedObject<T> =>
  Object.keys(obj).reduce((acc, key1) => {
    Object.keys(obj[key1]).forEach((key2) => {
      acc[key2] ??= {} as any
      // @ts-expect-error Type generic can only be indexed for reading.
      acc[key2][key1] = obj[key1][key2]
    })
    return acc
  }, {} as SwappedObject<T>)
