<template>
  <client-only>
    <div :class="{ container }">
      <component
        :is="`lazy-cms-${component}`"
        v-if="component"
        :key="segmentContent.id"
        :content="segmentContent"
        :data-test-id="`cms-${component}-${segmentContent.id}`"
      />
    </div>
    <template v-if="!enableCSROnlyForSegmentation" #fallback>
      <div :class="{ container }" class="skeleton">
        <div>
          <component
            :is="`lazy-cms-${component}`"
            v-if="component"
            :key="segmentContent.id"
            class="invisible"
            :content="segmentContent"
            :data-test-id="`cms-${component}-${segmentContent.id}`"
          />
        </div>
      </div>
    </template>
  </client-only>
</template>

<script lang="ts" setup>
import type { Segmentation } from '#types/components/cms/segmentation'
import mappings from '#content/mappings'

const props = defineProps<{
  content: Segmentation
}>()

const auth = useAuthStore()
const { isLoyaltyMember } = storeToRefs(useProfileStore())
const cart = useCartStore()
const query = useRouteQuery('p13n_testcontext', '{}')
const { enableCSROnlyForSegmentation } = useFeatureFlags()

const isEmployee = computed(() => auth.consumerType === 'EMPLOYEE')

const segmentsMap = computed(() => ({
  'loggedout': !auth.loggedIn,
  'loggedin': auth.loggedIn,
  'loyalty-loggedin': isLoyaltyMember.value,
  'non-loyalty-loggedin': auth.loggedIn && isLoyaltyMember.value,
  'employee-loggedin': auth.loggedIn && isEmployee.value,
  'non-employee-loggedin': auth.loggedIn && !isEmployee.value,
  'cart-empty': !cart.totalItems,
  'cart-not-empty': cart.totalItems
}))

// override can be set in the CMS to preview specific segments
const segmentOverride = computed(() => JSON.parse(query.value)?.segment)

const segmentContent = computed(() => {
  const segment = segmentOverride.value
    ? props.content.segments?.find(({ id }) => id === segmentOverride.value)
    : props.content.segments?.find(({ id }) => segmentsMap.value[id])
  return { ...segment?.targets?.[0] || props.content.baselines?.[0], sectionId: props.content.id }
})

const component = computed(() => {
  if (!segmentContent.value?.type) return null
  const module = mappings[segmentContent.value.type]
  return module?.component
    || (segmentContent.value.variant && module?.variants?.[segmentContent.value.variant]?.component)
})

/**
 * If the parent component is manually (!) set as full width we should take this as source for applying container class
 * Otherwise we should look into direct segment component configuration
 */
const container = computed(() => {
  if (typeof props.content !== 'undefined' && props.content.isFullWidth !== undefined)
    return !props.content.isFullWidth

  return !segmentContent.value?.isFullWidth
})

const emptySections = useState<Record<string, boolean>>('emptySections', () => ({}))

watch(component, (component) => {
  emptySections.value[props.content.id] = !component
}, { immediate: true })
</script>
