/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type { CreateSupportCaseData, CreateSupportCasePayload } from "./data-contracts";

export class Warranty extends HttpClient {
  static paths = { createSupportCase: "/consumers-v3/consumer/warranty" };
  static mockDataPaths = {};
  static mockedPaths = { createSupportCase: false };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "warranty");
  }

  /**
   * No description
   *
   * @tags Warranty
   * @name CreateSupportCase
   * @summary Create a support case with an optional attachment.
   * @request POST:/consumers-v3/consumer/warranty
   */
  createSupportCase = <
    ResT = CreateSupportCaseData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    query: {
      /** Captcha response for validation. */
      captchaResponse: string;
    },
    data: CreateSupportCasePayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/consumer/warranty")
        ? computed(() => mockPath("Warranty", "createSupportCase", ``))
        : computed(() => `/consumers-v3/consumer/warranty`),
      method: "POST",
      query: query,
      body: data,
      key: "/consumers-v3/consumer/warranty",
      apiName: "Warranty.createSupportCase",
      ...params,
    });
  /**
   * No description
   *
   * @tags Warranty
   * @name CreateSupportCase
   * @summary Create a support case with an optional attachment.
   * @request POST:/consumers-v3/consumer/warranty
   */
  $createSupportCase = (
    query: {
      /** Captcha response for validation. */
      captchaResponse: string;
    },
    data: CreateSupportCasePayload,
    requestParams: ApiOptions = {},
  ) =>
    this.$request<CreateSupportCaseData>({
      path: isMocked("/consumers-v3/consumer/warranty")
        ? mockPath("Warranty", "createSupportCase", ``)
        : `/consumers-v3/consumer/warranty`,
      method: "POST",
      query: query,
      body: data,
      apiName: "Warranty.createSupportCase",
      ...requestParams,
    });
}
