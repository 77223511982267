/**
 * @remark Store could be anything like user, cart, screen.
 * The first argument is a unique id of the store across your application
 */
import type { FlashError, PaymentInstrument, ShippingAddress } from '#types/cart'
import type { CreditCard, PaymentProvider } from '#types/components/account/creditCard'
import type { Order, OrderGroupedItems, PickupPerson } from '#types/components/checkout/order'
import type { PaymentMethodCode } from '#types/components/checkout/paymentMethods'
import { ApiErrorCode } from '#root/enums/api'

export const useCheckoutStore = (storeType = '') => defineStore(`checkout${storeType}`, () => {
  const { $dtrum, $t, $deviceFingerPrint, $feature } = useNuxtApp()
  const { cart: cartApi } = useApi()
  const klarna = useKlarna()
  const toast = useToaster()
  const forterSessionToken = useState('forterSessionToken')

  const auth = useAuthStore()
  const buyInStore = useBuyInStoreStore()
  const profile = useProfileStore()
  const cart = useCartStore(storeType)
  const { basketId, totals } = storeToRefs(cart)
  const { ModalThreeDsChallenge } = useDialogsStore()

  const paymentProvider = {} as PaymentProvider

  const {
    mobilePhone: collectionPhone,
    email: collectionEmail,
    collectionContact
  } = useAppConfig().components.form.collectionPoint
  const { email: storePickupEmail } = useAppConfig().components.form.storePickup
  const { sortBonusItemsFirst } = useAppConfig().components.checkout.cartContainer
  const { loyaltyType } = useAppConfig().pages.checkout

  const order = ref({} as Order)
  const bin = ref()
  const isLegalAccepted = ref(false)
  const checkoutTotalItems = ref(0)
  const isOrderFinalized = ref(false)
  const billingAddressRef = ref()
  const isOrderProcessing = ref(false)
  const isCreditCardExpired = ref(false)
  const checkoutStatus = ref()
  const checkoutStep = ref<'shipping' | 'billing' | 'summary'>('shipping')
  // used to set billing address same as shipping address
  const shippingAddress = ref<ShippingAddress>()
  const threeDS2Challenge = ref<string>('')
  const loading = ref(false)
  const defaultCardData = ref<CreditCard | null>(null)

  // check for the context of the pinia store to decide on passing a necessary query parameter (it can be done with switch if need more contexts in the future)
  const queryParams = computed(() => storeType === 'applePayPdp' ? { bid: 'cookie' } : undefined)

  // success() normalizes data from the API response to better support the design of the confirmation page
  function success(orderData: Order) {
    isOrderFinalized.value = true

    const groupItems = groupItemsByShipment(orderData.items)
    const getSortIndex = (deliveryTime: string) => /same day/i.test(deliveryTime) ? 0 : Number.parseInt(deliveryTime.match(/(\d)-\d/)?.[1] || '1')

    const shipments = [
      // Put standard items before pickup items
      ...orderData.shipments.filter(({ shippingMethod }) => !isPickupOrSts(shippingMethod.code))
        .sort(({ shipmentId: a }, { shipmentId: b }) => {
          return sortBonusItemsFirst
            ? +groupItems(b).some(({ bonus }) => bonus) - +groupItems(a).some(({ bonus }) => bonus)
            : 0
        }),
      // Sort pickup items by deliveryTime
      ...orderData.shipments.filter(({ shippingMethod }) => isPickupOrSts(shippingMethod.code))
        .sort(({ shippingMethod: a }, { shippingMethod: b }) => {
          return getSortIndex(a.deliveryTime) - getSortIndex(b.deliveryTime)
        })
    ]
    order.value = {
      ...orderData,
      totals: {
        ...orderData.totals,
        totalDiscountPercentage: getPercentage(orderData.totals.itemTotal, orderData.totals.totalDiscount)
      },
      items: orderData.items
        .map((item) => ({
          ...item,
          shippingOptions: item.shippingOptions?.map((option) => ({
            ...option,
            shippingMethod: {
              ...option.shippingMethod,
              // The shipping options label on confirmation page needs to show a static text rather than the label from API response
              label: getShippingMethodLabel($t, option.shippingMethod)
            }
          }))
        }))
        .sort((a, b) => {
          return sortBonusItemsFirst
            ? +b.bonus - +a.bonus
            : 0
        }),
      shipments
    }

    // DynaTrace Real User Monitoring: Add payment type data to session
    $dtrum?.sendSessionProperties(
      null,
      null,
      {
        payment_type: orderData.paymentMethod?.[0].label
      }
    )
  }

  function resetOrder() {
    order.value = {} as Order
    isOrderFinalized.value = false
    isOrderProcessing.value = false
    checkoutTotalItems.value = 0
  }

  /**
   * Needed this sepatate function to pass SQ Cognitive Complexity check
   */
  function containsFlashError(err: any) {
    if (err.response?._data?.errorDetails?.[0]?.message?.some?.((item: FlashError) => item._type === 'flash'))
      return true
  }

  function isAuthorized(response: Order) {
    const notAuthorized = response.flash?.find(({ code }) => code === 'PaymentNotAuthorized')
    if (notAuthorized) {
      threeDS2Challenge.value = notAuthorized.details?.action || ''
      order.value = response
      return false
    }
    return true
  }
  /**
   * Handles the Place Order Logic
   * @param paymentMethod Object storing the payment ready details to place order
   * @param recaptcha The return of composable useRecaptcha()
   */
  async function placeOrder(paymentMethod, recaptcha: ReturnType<typeof useRecaptcha>) {
    isOrderProcessing.value = true
    type OrderBody = {
      cartId: string
      billingAddress?: typeof billingAddressRef.value
      paymentMethod: typeof paymentMethod
      inStoreSalesInfo?: {
        storeId: string
        storeEmployeeId?: string
      }
    }
    const orderBody: OrderBody = {
      cartId: basketId.value,
      billingAddress: {
        ...billingAddressRef.value,
        phone: billingAddressRef.value.phone
          ? formatE164phone(
            billingAddressRef.value.phone,
            billingAddressRef.value.phoneCode
          )
          : ''
      },
      paymentMethod: {
        ...paymentMethod
      }
    }

    if (buyInStore.storeInfo?.storeId) {
      const { storeId, storeEmployeeId } = buyInStore.storeInfo
      orderBody.inStoreSalesInfo = {
        storeId,
        storeEmployeeId
      }
    }

    if (paymentMethod.code === 'PAYPAL' && !paymentMethod.id)
      delete orderBody.billingAddress

    if (paymentMethod.code === 'KLARNA') {
      try {
        const result = await klarna
          .authorize(paymentMethod.billingAddress!, paymentMethod.shippingAddress!)

        orderBody.paymentMethod.additionalData = [result]
      }
      catch (error) {
        // If the actual error happened during the authorization process we need to handle it here
        if (error instanceof Error)
          cart.setNotification('error', $t.apiMessages.PAY400, 'top')

        // If the user closed the Klarna modal we don't want to show any error message
        isOrderProcessing.value = false
        return
      }
    }
    else {
      // add device fingerprint session id to payment details
      orderBody.paymentMethod.additionalData = orderBody.paymentMethod.additionalData.map((obj) => {
        obj.sessionID = forterSessionToken.value || $deviceFingerPrint.getSessionId()
        return obj
      })
      if (bin.value) {
        orderBody.paymentMethod.bin = bin.value.binValue
        orderBody.paymentMethod.type = bin.value.type
      }
    }

    try {
      const captchaResponse = await recaptcha.execute('placeOrder')
      const orderResponse = await cartApi.$placeOrder({
        recaptcha_response: captchaResponse,
        ...orderBody
      }, {
      })

      const checkIsAuthorized = isAuthorized(orderResponse as Order)
      if (!checkIsAuthorized) {
        // if payment not authorized open challenge modal
        if (order.value.flash?.[0]?.message !== 'redirect')
          ModalThreeDsChallenge.open({ showTitle: true })
        return false
      }

      try {
        success(orderResponse as Order) // preprare data for confirmation page
        // clear delivery options from session storage when the order is successfully placed
        const deliveryOptions = useSessionStorage('deliveryOptions', null)
        deliveryOptions.value = undefined
      }
      finally {
        log.info(`order placed: ${orderResponse.orderNumber}`)
        await navigateTo('/order-confirmation')
        cart.reset()
        checkoutStep.value = 'shipping' // reset the checkout status
      }
    }
    catch (err: any) {
      assertApiError(err)
      // if errorId === ORD430
      if (err.errorId === ApiErrorCode.ITEM_NO_LONGER_AVAILABLE) {
        // retrieve the cart to update the cart items, totals, etc.
        await cart.get()
        // handle flash messages in errorDetails. should be after cart.get() to avoid overriding the flash messages
        const flashMessages = err.response?._data?.errorDetails?.[0]?.message || []
        if (flashMessages.length) {
          // if there is a flash message regarding shipping transition, we need to send user to the shipping step for entering(or selecting) their delivery address
          flashMessages.forEach((msg) => {
            if ([
              'PickupToSthTransition',
              'StsToSthTransition',
              'InvalidShippingAddress'
            ].includes(msg.type))
              checkoutStep.value = 'shipping'
          })
          cart.handleFlashMessages(flashMessages, false)
        }
      }
      else {
        let showToast = true
        if ([
          ApiErrorCode.CC_EXPIRY_DATE_INVALID,
          ApiErrorCode.PAYMENT_INSTRUMENT_NOT_FOUND,
          ApiErrorCode.ADDRESS_NOT_FOUND,
          ApiErrorCode.PAYMENT_ERROR,
          ApiErrorCode.PAYMENT_FRAUD_VERIFICATION_EXCEPTION,
          ApiErrorCode.CART_NOT_FOUND,
          ApiErrorCode.SHIPPING_POBOX_UNAVAILABLE,
          ApiErrorCode.SOME_PRODUCTS_NO_INVENTORY
        ].includes(err.errorId))
          showToast = false

        if (containsFlashError(err)) {
          showToast = false
          await cart.get()
        }

        if (showToast) {
          toast.add({
            autoClose: false,
            props: {
              message: err.message,
              type: 'error'
            }
          })
        }
        else {
          cart.setNotification('error', err.message, 'top')
          cart.error = !!paymentMethod.id
        }
      }
    }
    finally {
      isOrderProcessing.value = false
    }
  }
  /**
   * Handles the ThreeDS2 Challenge authorization
   */
  async function patchOrder(threeDS2Result, code: Extract<PaymentMethodCode, 'BANCONTACT' | 'CREDIT_CARD' | 'IDEAL'>) {
    isOrderProcessing.value = true
    try {
      const threeDSType = order.value.flash?.[0]?.message
      const additionalData = order.value.paymentMethod.find((method) => method.code === code)?.additionalData
      const patchResponse = await cartApi.$patchOrder(order.value.orderNumber, {
        paymentMethod: {
          code,
          additionalData: [
            {
              cardType: additionalData?.card_type,
              paymentInstrumentId: additionalData?.payment_instrument_id,
              sessionID: forterSessionToken.value || $deviceFingerPrint.getSessionId(),
              threeDS2Result: threeDSType === 'redirect'
                ? JSON.stringify({
                  details: { redirectResult: threeDS2Result }
                })
                : JSON.stringify(threeDS2Result.data)
            }
          ]
        }
      })
      const checkIsAuthorized = isAuthorized(patchResponse)
      if (!checkIsAuthorized) {
        checkoutStep.value = 'billing'
        cart.setNotification('error', $t.paymentAuthenticationError, 'top')
        resetOrder()
        cart.basketId = (await cartApi.$create({})).id!
        return false
      }

      try {
        success(patchResponse) // preprare data for confirmation page
        return true
      }
      finally {
        log.info(`order placed with 3DS Challenge: ${patchResponse.orderNumber}`)
        await navigateTo('/order-confirmation')
        // on successful order reset references we don't want to keep
        cart.reset()
        defaultCardData.value = null
        shippingAddress.value = undefined
        checkoutStep.value = 'shipping'
      }
    }
    catch (err: any) {
      checkoutStep.value = 'billing'
      cart.setNotification('error', err.message, 'top')
      assertApiError(err)
      resetOrder()
      cart.basketId = (await cartApi.$create({})).id!
    }
    finally {
      isOrderProcessing.value = false
    }
  }

  async function prepareOrder(status?: string) {
    try {
      const orderPrepareResponse: any = await cartApi.$prepareOrder({
        basketId: basketId.value
      }, queryParams.value)
      cart.updateCartData(orderPrepareResponse, {
        emitUpdateEvent: false,
        keepNotifications: !cart.itemOOSNotificationActive && !!cart.notification.message
      })
      checkoutTotalItems.value = orderPrepareResponse.totalItems
      // make sure we re-sync the order totals with the cart totals in case there were changes in the meantime
      totals.value = {
        ...orderPrepareResponse.totals,
        totalDiscountPercentage:
          getPercentage(orderPrepareResponse.totals.itemTotal, orderPrepareResponse.totals.totalDiscount)
      }
    }
    catch (err) {
      assertApiError(err)
      cart.setNotification('error', err.message, 'top')
    }
    finally {
      checkoutStatus.value = status || ''
    }
  }

  const promoSummary = computed(() => order.value?.promoSummary?.map((promo) =>
    ({ ...promo, promotionName: promo.promotionName || promo.promotionId })) || [])
  const orderPromoSummary = computed(() => promoSummary.value.filter(({ level }) => level === 'ORDER'))
  const productPromoSummary = computed(() => promoSummary.value.filter(({ level }) => level === 'PRODUCT'))
  const shippingPromoSummary = computed(() => promoSummary.value.filter(({ level }) => level === 'SHIPPING'))

  const orderItemsPerShippingMethod = computed(() => {
    // sorts pickup for last so its presented on any list as last
    const sortedItems = [...order.value?.items]

    sortedItems.sort((a, b) => {
      const isAPickup
        = isPickupOrSts(a.shippingOptions?.find(({ selected }) => selected)?.shippingMethod.code)
      const isBPickup
        = isPickupOrSts(b.shippingOptions?.find(({ selected }) => selected)?.shippingMethod.code)

      if (isAPickup === isBPickup)
        return 0

      return isAPickup ? 1 : -1
    })

    const groups: OrderGroupedItems = sortedItems.reduce((gp, it) => {
      const groupKey
        = isPickupOrSts(it.shippingOptions?.find(({ selected }) => selected)?.shippingMethod.code)
          ? 'pickup'
          : 'home'

      if (!gp[groupKey])
        gp[groupKey] = { items: [], shipments: [] }

      gp[groupKey]?.items.push(it)

      return gp
    }, {})

    if (groups.home)
      groups.home.shipments = order.value?.shipments?.filter((ship) => !ship.storeId)

    if (groups.pickup)
      groups.pickup.shipments = order.value?.shipments?.filter((ship) => !!ship.storeId)

    return groups
  })

  const pickupItems = computed(() => cart.shippingMethods
    ?.find((shp) => shp.code === cart.itemsPerShippingMethod.pickup?.items[0]
      ?.selectedShippingOption.shippingMethod.code))

  const isPickupPersonSet = computed(() => {
    if (pickupItems.value) {
      const { firstName, lastName, email } = pickupItems.value.address
      return firstName && lastName && email
    }
    return false
  })

  const skipCheckoutForms = async (recaptcha: ReturnType<typeof useRecaptcha>) => {
    const creditCards = await useApi().consumer.$getPaymentInstruments({
      payment_method_id: 'CREDIT_CARD'
    })

    const defaultCreditCard = creditCards.find((cc: CreditCard) => cc.card?.main)?.card || null
    const defaultCardAddress = creditCards.find((cc: CreditCard) => cc.card?.main)?.address || null
    // if we have pickup items, but don't have a pickup person set, update pickup person details with logged in user profile.
    const pickupPerson: PickupPerson = pickupItems.value && !isPickupPersonSet.value
      ? {
          firstName: profile.details?.firstName,
          lastName: profile.details?.lastName,
          email: profile.details?.email,
          shippingId: pickupItems.value.shippingId,
          storeId: pickupItems.value.storeId
        }
      : {}
    const hasShippingAddress = shippingAddress.value?.firstName
    // we can skip to the summary step only if we have default credit card and (default address or entered/edited address)
    if (defaultCreditCard && (profile?.defaultShippingAddress || hasShippingAddress || cart.hasPickupItemsOnly)) {
      checkoutStatus.value = 'EXPRESS'
      defaultCardData.value = {
        card: defaultCreditCard,
        address: defaultCardAddress
      }
      // if pickup only order use pickup address otherwise if user changed/edited address in checkout step, update cart with that address, otherwise use default shipping address
      let address = convertToShippingAddress(profile.defaultShippingAddress)
      if (pickupItems.value && cart.hasPickupItemsOnly)
        address = pickupItems.value.address
      else if (shippingAddress.value)
        address = shippingAddress.value
      const captchaResponse = $feature.enableShippingAddressRecaptcha ? await recaptcha.execute('login') : ''
      await useApi().cart.$addShippingAddress(basketId.value, {
        ...address,
        ...pickupPerson,
        ...captchaResponse && { recaptcha_response: captchaResponse }
      })
      // prepare the order to get all the correct taxes
      await prepareOrder('EXPRESS')
      checkoutStep.value = 'summary'
      loading.value = false
    }
    else {
      checkoutStep.value = 'shipping'
      loading.value = false
    }
  }
  // go through chekcs for fast checkout only if the flag is on and user is logged in
  const expressCheckout = async (recaptcha: ReturnType<typeof useRecaptcha>) => {
    if ($feature.allowExpressCheckoutWithDefaults && auth.loggedIn) {
      loading.value = true
      await skipCheckoutForms(recaptcha)
    }
    else {
      checkoutStep.value = 'shipping'
    }
  }

  return {
    billingAddressRef,
    bin,
    checkoutStep,
    isOrderFinalized,
    isOrderProcessing,
    order,
    giftCards: computed<PaymentInstrument[]>(() => (order.value?.paymentMethod || [])
      .filter(({ additionalData }) => additionalData.payment_method_id === 'GIFT_CARD')
      .map(({ additionalData }) => additionalData)),
    rewards: computed(() => (order.value?.paymentMethod || [])
      .filter(({ additionalData }) =>
        loyaltyType.includes(additionalData.payment_method_id))
      .map(({ additionalData }) => additionalData)),
    placeOrder,
    patchOrder,
    prepareOrder,
    orderPromoSummary,
    productPromoSummary,
    shippingPromoSummary,
    resetOrder,
    shippingAddress,
    checkoutTotalItems,
    success,
    threeDS2Challenge,
    orderItemsPerShippingMethod,
    loading,
    expressCheckout,
    paymentProvider,
    defaultCardData,
    checkoutStatus,
    hasDigitalItems: computed(() => order.value?.shipments.some(isDigitalDelivery)),
    hasDigitalItemsOnly: computed(() => order.value?.shipments.every(isDigitalDelivery)),
    isCreditCardExpired,
    isCollectionPointEmailRequired: computed(() => collectionEmail?.requiredLocales.includes(useLocale())),
    isCollectionPointPhoneRequired: computed(() => collectionPhone?.requiredLocales.includes(useLocale())),
    isCollectionContactRequired: computed(() => collectionContact?.requiredLocales.includes(useLocale())),
    isStorePickupEmailRequired: computed(() => storePickupEmail?.requiredLocales.includes(useLocale())),
    isLegalAccepted,
    athletePayment: computed(() => (order.value?.paymentMethod || []).find(({ additionalData }) => additionalData.payment_method_id === 'ATHLETES_PAYMENT')?.additionalData)
  }
}, {
  persist: {
    storage: persistedState.localStorage,
    key: 'checkout',
    paths: ['order']
  }
})()
