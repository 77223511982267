<template>
  <vf-pop-up
    v-model="expanded"
    v-style="{ bg: backgroundColor, c: titleColor }"
    class="z-header"
    :action
    :category
    @close="close"
  >
    <template #title>
      <div class="flex items-center">
        <base-picture
          v-if="logo"
          class="mr-2"
          :src="getMediaUrl(logo.url)"
          :alt="logo.alt"
          :height="logo.height"
          :width="logo.width"
          :props-img="{ class: 'w-a min-w-16' }"
          :style="{ height: logoSize === 'small' ? '1.125rem' : '1.625rem' }"
        />
        <span v-if="minimizedTitle" class="truncate" :class="logoSize === 'small' ? 'title-5' : 'title-3'">{{ minimizedTitle }}</span>
      </div>
    </template>
    <template #titleExpanded>
      <div class="flex items-center">
        <base-picture
          v-if="logo"
          class="mr-2"
          :src="getMediaUrl(logo.url)"
          :alt="logo.alt"
          :height="logo.height"
          :width="logo.width"
          :props-img="{ class: 'w-a min-w-16' }"
          :style="{ height: '1.5rem' }"
        />
        <span v-if="maximizedTitle" class="subtitle-5 truncate">{{ maximizedTitle }}</span>
      </div>
    </template>
    <div class="text-center space-y-4">
      <h2 v-if="title" class="title-3">
        {{ title }}
      </h2>
      <div v-if="targets.length" class="flex gap-2">
        <cms-shared-button
          v-for="(target, key) in targets"
          :key
          v-bind="target"
          :size="{ sm: { size: 'xs' } }"
          class="flex-1"
          @click="$gtm.push('popUp.onClick', category, action, target?.text)"
        />
      </div>
      <cms-rich-text v-if="disclaimer" v-style:c="disclaimerColor" class="text-xs" :content="{ richText: disclaimer }" />
    </div>
  </vf-pop-up>
</template>

<script setup lang="ts">
import type { PopUpContent } from '#types/components/cms/pop-up'

const { content } = defineProps<{
  content: PopUpContent
}>()

const {
  backgroundColor,
  delay,
  disclaimer,
  disclaimerColor,
  name,
  minimizedTitle,
  targets = [],
  title,
  titleColor,
  logo,
  logoSize = 'small',
  maximizedTitle
} = content

const { close } = usePopUp()
const { getMediaUrl } = useCms()

const expanded = ref(false)

const isNewsLetterPopup = name?.toLowerCase()?.includes('newsletter')
const category = isNewsLetterPopup ? 'Newsletter' : 'Account'
const action = isNewsLetterPopup ? minimizedTitle : 'Registration'

setTimeout(() => expanded.value = true, delay || 2000)
</script>
