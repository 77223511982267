/**
 * Extracts the ID from the URL path based on the page type.
 * @param path The URL path.
 * @param pageType The type of the page ('Product' or 'Category').
 * @returns The ID extracted from the URL path.
 * @category Utils
 */
export const getUrlId = (path: string, pageType: 'Product' | 'Category') =>
  path.match(pageType === 'Product'
    ? PRODUCT_URL_REGEX
    : CATEGORY_URL_REGEX
  )?.[1] || ''
