<template>
  <div
    :class="[classes, { '[&_img]:w-full': content.isFullWidth }]"
    :style="{ '--ch': linksHoverColor || defaultLinksHoverColor }"
    data-test-id="cms-rich-text"
    v-html="content.richText?.html"
  />
</template>

<script lang="ts" setup>
import type { RichTextContent } from '#types/components/cms/rich-text'

defineProps<{
  content: RichTextContent
  linksHoverColor?: string
}>()

const { defaultLinksHoverColor } = useAppConfig().components.cms.richText

const classes = `:uno:
  [&_a]:underlined [&_a]:duration
  [&_blockquote]:ml-4
  [&_img]:i-block
  [&_table]:border-separate
  [&_table,&_th,&_td]:b [&_table,&_th,&_td]:b-dotted
  [&_td]:p-2
  [&_sup,&_sub]:text-[60%]
  [&_a:hover]:c-$ch
  [&_ol]:list-decimal [&_ol]:pl-6 [&_ol]:text-left
  [&_ol.text-center]:pl-1/3
  [&_ol.text-right]:pl-2/3
  [.\<md\:text-center_&_ol]:<md:pl-1/3
  [.\<md\:text-right_&_ol]:<md:pl-2/3
  [.md\:text-center_&_ol]:md:pl-1/3
  [.md\:text-right_&_ol]:md:pl-2/3
  [&_ul]:list-disc [&_ul]:pl-6 [&_ul]:text-left
  [&_ul.text-center]:pl-1/3
  [&_ul.text-right]:pl-2/3
  [.\<md\:text-center_&_ul]:<md:pl-1/3
  [.\<md\:text-right_&_ul]:<md:pl-2/3
  [.md\:text-center_&_ul]:md:pl-1/3
  [.md\:text-right_&_ul]:md:pl-2/3
`
</script>
