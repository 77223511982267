<template>
  <cms-experience v-if="popUp" :content="popUp" />
</template>

<script setup lang="ts">
const { $gtm } = useNuxtApp()
const { getPopUps } = useCms()
const { open, popUp } = usePopUp()

const data = await getPopUps()
if (data) {
  const { name, minimizedTitle } = data?.items[0]?.baselines[0] || {}
  const isNewsLetterPopup = name?.toLowerCase()?.includes('newsletter')

  const category = isNewsLetterPopup ? 'Newsletter' : 'Account'
  const action = isNewsLetterPopup ? minimizedTitle : 'Registration'

  $gtm.push('popUp.onImpression', category, action)
  open(data.items, category, action)
}
</script>
