/**
 * useApiCookie composable
 * This composable reads cookies set by the API based on the current site's id
 * @returns the useCookie composable for the required cookie
 */
export const useApiCookie = <T = string | null>(type: string) => {
  return useCookie<T>(`vfa_${useSiteId()}_${type}`)
}

export const refreshApiCookie = (type: string) => refreshCookie(`vfa_${useSiteId()}_${type}`)
