<template>
  <section data-test-id="cms-ugc-olapic">
    <template v-if="showStaticText">
      <h2 class="pr-4 i-block title-2">
        {{ $t.olapic.title }}
      </h2>
      <span class="b-l b-grey-50 pl-4 subtitle-2">{{ $t.olapic.subtitle }}</span>
    </template>
    <div :id="IDENTIFIER" />
  </section>
</template>

<script lang="ts" setup>
import type { UGCContent } from '#types/components/cms/ugc'

const { content } = defineProps<{ content: UGCContent }>()
const { defaultLocale, locales, url } = useAppConfig().components.cms.ugc.olapic

const route = useRoute()

const widgetId = content.containerId
const locale = locales[useLanguageCode()] || defaultLocale
const WIDGET_BASE_URL = url
const WIDGET_SRC = `${WIDGET_BASE_URL}/${widgetId}/${locale}`
const IDENTIFIER = `olapic-widget-${widgetId}`
const SCRIPT_ID = `${IDENTIFIER}-script`
let dataTag = ''

const productId = getUrlId(route.path, 'Product')
const isOlapicComponentOnCustomsPage = route.fullPath.includes('customs')
const showStaticText = !isOlapicComponentOnCustomsPage && !productId

if (productId) {
  const { products } = useApi()
  try {
    const { data: product } = await products.details(productId, {}, {
      dedupe: 'defer',
      key: `productDetails-${productId}`,
      lazy: true
    })
    dataTag = product.value?.masterId || ''
  }
  catch (err) {
    log.error(`[@domains/content/components/cms/ugc/Olapic.client.vue] ${err}`)
  }
}

const { $script } = useScript({
  'key': `${SCRIPT_ID}-${dataTag}`,
  'src': WIDGET_SRC,
  'async': true,
  'id': SCRIPT_ID,
  'data-tag': dataTag || undefined
})

$script
  .then(() => {
    log.info(`[@Olapic.client] widget id <${widgetId}>, locale <${locale}> and tag <${dataTag}>`)
  })
  .catch(() => log.error(`[@Olapic.client]unloaded widget id <${widgetId}>, locale <${locale}> and tag <${dataTag}>`))

onBeforeUnmount(() => $script.remove())
</script>
