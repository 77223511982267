/**
 * @param phone - phone number that may or may not include the country code
 * @param phoneCode - the country phone code
 * @returns a phone number in e164 format: +{phone code}{phone number}
 */

export const formatE164phone = (phone: string, phoneCode?: string): string => {
  // if no phone is provided, return an empty string
  if (!phone) return ''

  // if phone starts with a +, phone should be already in e164 format
  if (phone.startsWith('+'))
    return phone

  // we may receive a complete full phone (code+phone), just without the + sign
  // try to split the phone received: if phone is a full phone, it should be equal to _code+_phone and, if provided, the phoneCode should be equal to _code
  const [_code, _phone] = parseE164Phone(phone)
  if (phone === `${_code}${_phone}` && (phoneCode && (phoneCode === _code))) return `+${phone}`

  // if we have both phone and code, return them together appending the + sign
  if (phoneCode) return `+${phoneCode}${phone}`

  // we should never reach this part: no phoneCode and phone isn't a full or e164 phone
  // we return the best guess, obtained from the parse function
  return `+${_code}${_phone}`
}
