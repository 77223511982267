<template>
  <component
    :is="isMegaMenuMobile ? LazyCmsMegaMenuL3Mobile : LazyCmsMegaMenuL3Desktop"
    v-bind="{ content }"
    @click="handleClick"
  />
</template>

<script lang="ts" setup>
import { MegaMenuMobileContextKey } from './context'
import { LazyCmsMegaMenuL3Desktop, LazyCmsMegaMenuL3Mobile } from '#components'
import type { MenuItem } from '#types/components/cms/mega-menu'

defineProps<{ content: MenuItem }>()

const { $gtm } = useNuxtApp()
const isMegaMenuMobile = inject(MegaMenuMobileContextKey, false)
const handleClick = (label) => $gtm.push('topNav.onClickTopNavLink', fullCapitalize(label))
</script>
