/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type { CountryListData, EmployeesData, I18NData, StoresData } from "./data-contracts";

export class Utilities extends HttpClient {
  static paths = {
    countryList: "/consumers-v3/utilities/countryList",
    i18N: "/consumers-v3/utilities/i18n/:countryCode",
    stores: "/consumers-v3/utilities/stores",
    employees: "/consumers-v3/utilities/stores/:storeId/employees",
  };
  static mockDataPaths = {};
  static mockedPaths = { countryList: false, i18N: false, stores: false, employees: false };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "utilities");
  }

  /**
   * No description
   *
   * @tags Utilities
   * @name CountryList
   * @summary Country List
   * @request GET:/consumers-v3/utilities/countryList
   */
  countryList = <ResT = CountryListData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    query?: {
      /** The active locale */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/utilities/countryList")
        ? computed(() => mockPath("Utilities", "countryList", ``))
        : computed(() => `/consumers-v3/utilities/countryList`),
      method: "GET",
      query: query,
      key: "/consumers-v3/utilities/countryList",
      apiName: "Utilities.countryList",
      ...params,
    });
  /**
   * No description
   *
   * @tags Utilities
   * @name CountryList
   * @summary Country List
   * @request GET:/consumers-v3/utilities/countryList
   */
  $countryList = (
    query?: {
      /** The active locale */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<CountryListData>({
      path: isMocked("/consumers-v3/utilities/countryList")
        ? mockPath("Utilities", "countryList", ``)
        : `/consumers-v3/utilities/countryList`,
      method: "GET",
      query: query,
      apiName: "Utilities.countryList",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Utilities
   * @name I18N
   * @summary I18n
   * @request GET:/consumers-v3/utilities/i18n/{countryCode}
   */
  i18N = <ResT = I18NData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    countryCode: MaybeRefOrGetter<string>,
    query?: {
      /** The active locale */
      locale?: string;
    },
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/utilities/i18n/:countryCode")
        ? computed(() => mockPath("Utilities", "i18N", `countryCode:${toValue(countryCode)}`))
        : computed(() => `/consumers-v3/utilities/i18n/${toValue(countryCode)}`),
      method: "GET",
      query: query,
      key: "/consumers-v3/utilities/i18n/:countryCode",
      apiName: "Utilities.i18N",
      ...params,
    });
  /**
   * No description
   *
   * @tags Utilities
   * @name I18N
   * @summary I18n
   * @request GET:/consumers-v3/utilities/i18n/{countryCode}
   */
  $i18N = (
    countryCode: string,
    query?: {
      /** The active locale */
      locale?: string;
    },
    requestParams: ApiOptions = {},
  ) =>
    this.$request<I18NData>({
      path: isMocked("/consumers-v3/utilities/i18n/:countryCode")
        ? mockPath("Utilities", "i18N", `countryCode:${toValue(countryCode)}`)
        : `/consumers-v3/utilities/i18n/${countryCode}`,
      method: "GET",
      query: query,
      apiName: "Utilities.i18N",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Utilities
   * @name Stores
   * @summary Stores
   * @request GET:/consumers-v3/utilities/stores
   */
  stores = <ResT = StoresData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/utilities/stores")
        ? computed(() => mockPath("Utilities", "stores", ``))
        : computed(() => `/consumers-v3/utilities/stores`),
      method: "GET",
      key: "/consumers-v3/utilities/stores",
      apiName: "Utilities.stores",
      ...params,
    });
  /**
   * No description
   *
   * @tags Utilities
   * @name Stores
   * @summary Stores
   * @request GET:/consumers-v3/utilities/stores
   */
  $stores = (requestParams: ApiOptions = {}) =>
    this.$request<StoresData>({
      path: isMocked("/consumers-v3/utilities/stores")
        ? mockPath("Utilities", "stores", ``)
        : `/consumers-v3/utilities/stores`,
      method: "GET",
      apiName: "Utilities.stores",
      ...requestParams,
    });
  /**
   * No description
   *
   * @tags Utilities
   * @name Employees
   * @summary Employees
   * @request GET:/consumers-v3/utilities/stores/{storeId}/employees
   */
  employees = <ResT = EmployeesData, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
    storeId: MaybeRefOrGetter<string>,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/consumers-v3/utilities/stores/:storeId/employees")
        ? computed(() => mockPath("Utilities", "employees", `storeId:${toValue(storeId)}`))
        : computed(() => `/consumers-v3/utilities/stores/${toValue(storeId)}/employees`),
      method: "GET",
      key: "/consumers-v3/utilities/stores/:storeId/employees",
      apiName: "Utilities.employees",
      ...params,
    });
  /**
   * No description
   *
   * @tags Utilities
   * @name Employees
   * @summary Employees
   * @request GET:/consumers-v3/utilities/stores/{storeId}/employees
   */
  $employees = (storeId: string, requestParams: ApiOptions = {}) =>
    this.$request<EmployeesData>({
      path: isMocked("/consumers-v3/utilities/stores/:storeId/employees")
        ? mockPath("Utilities", "employees", `storeId:${toValue(storeId)}`)
        : `/consumers-v3/utilities/stores/${storeId}/employees`,
      method: "GET",
      apiName: "Utilities.employees",
      ...requestParams,
    });
}
