import { apiPaths, mockedApiPaths } from '#root/api'
import { findObjectEntry } from '#core/utils/object/findObjectEntry'

type ToBool<T> = T extends object
  ? PropertiesToBool<T>
  : boolean

type PropertiesToBool<T> = {
  [K in keyof T]: ToBool<T[K]>
}

/**
 * @type {{ Client: { method: boolean } }}
 * This type defines a nested object of the following type:
 *
 * {
 *  Client: {
 *    method: boolean
 *  }
 * }
 *
 * It contains a full list of all clients and methods in the generated API client,
 * with the boolean values indicating whether each method is mocked or not.
 */
export type ApiMockConfig = PropertiesToBool<typeof apiPaths>

/**
 * Checks whether the mocking for a given method of a given client has been enabled.
 * As a configuration, it expects either a string representation of a boolean value
 * or an array of strings of the type Client.method.
 * If the value is true or the given client and method are in the array, the method is mocked.
 * If the value is false or the given client and method are NOT in the array, the method is NOT mocked.
 *
 * @param client The name of the client to check
 * @param path The name of the method to check
 * @param config The full config
 */
const convertMockConfigValueToFlag = (client: string, path: string, config: boolean | string[]) => {
  if (typeof config === 'boolean')
    return config

  return config.includes(`${client}.${path}`)
}

/**
 * Generates a full {@see ApiMockConfig} object from a given string configuration.
 * For a description of the string config, check {@see generateApiMockConfig}
 *
 * @param config The mock configuration
 */
const normaliseConfig = (config: boolean | string[]): ApiMockConfig => Object.keys(apiPaths)
  .reduce((clients, client) => ({
    ...clients,
    [client]: Object.keys(apiPaths[client]).reduce((paths, path) => ({
      ...paths,
      [path]: convertMockConfigValueToFlag(client, path, config)
    }), {})
  }), {} as ApiMockConfig)

/**
 * Generic getter that consistently converts an API endpoint path to its mocked counterpart
 *
 * @param clientName
 * @param methodName
 * @param paramsStr
 */
export const mockPath = (clientName: string, methodName: string, paramsStr?: string) => {
  const mockApiStatusCookie = useApiCookie('mock_status')
  const status = mockApiStatusCookie.value?.[`${clientName.toLowerCase()}.${methodName.toLowerCase()}`] || 200
  return `/mock/${clientName}.${methodName}.${paramsStr}.${status}`.replace(/\.$/, '')
}

/**
 * Predicate which checks whether an API endpoint path has been replaced with its mocked counterpart
 *
 * @param path The path to check
 * @returns Flag indicating whether it's a mock path
 */
export const isMockPath = (path: string) => path.includes('/mock/')

/**
 * Handles a string API mocking configuration as provided in an env variable or a query string parameter.
 * Always returns a full isntance of {@see ApiMockConfig} with all existing methods and their status,
 * with 'true' and 'false' resulting in all values being the respective boolean value,
 * and a CSV resluting in each method being toggled based on its presence as a substring
 *
 * @param config The provided configuration
 */
export const parseApiMockConfigString = (config: string) => {
  if (config === 'true' || config === 'false')
    return normaliseConfig(JSON.parse(config))

  return normaliseConfig(config.split(',').map((c) => c.trim()))
}

/**
 * Returns an {@see ApiMockConfig} indicating the mocking status of each API method based on the
 * current state of the configuration.
 *
 * Supports the following sources in order precedence:
 *   - The API_MOCK_CONFIG environment variable
 *   - The apiMockConfig query string parameter
 *
 * The supported sources can have the following values:
 *   - 'true' - all endpoints are mocked
 *   - 'false' - no endpoints are mocked
 *   - comma-delimited string - the included endpoints are mocked
 *
 * The comma-separated values need to be in the Client.method format, so e.g. if we want to
 * mock the 'catalog' and 'details' methods in the 'Products' client, we'd have the value
 * 'Products.catalog,Products.details'
 */
export const generateApiMockConfig = () => {
  const configCookie = useApiCookie<ApiMockConfig>(cookiesPostfix.Mock)
  const configState = useState<ApiMockConfig>('api.mock.config', () => configCookie.value)

  if (!configState.value || useRoute().query.apiMockConfig) {
    const envConfig = parseApiMockConfigString(useRuntimeConfig().public.api?.mockConfig || '')
    const queryConfig = parseApiMockConfigString(useRoute().query.apiMockConfig as string || '')
    const config = mergeDeep(envConfig, queryConfig, mockedApiPaths, (origin, patch) => {
      if (typeof origin === 'boolean' && typeof patch === 'boolean') return origin || patch
    })

    // Remove false values to reduce the payload size
    Object.keys(config).forEach((client) => {
      Object.keys(config[client]).forEach((endpoint) => {
        if (config[client][endpoint] === false)
          delete config[client][endpoint]
      })

      if (!Object.keys(config[client]).length)
        delete config[client]
    })

    configState.value = config
    configCookie.value = config
  }

  return configState.value
}

/**
 * Determines if the provided API endpoint (with :placeholders for path parameters) is mocked
 * based on the configuration, as described in {@see generateApiMockConfig}
 *
 * The current state of the configuration is persisted in the 'api' Pinia store
 * and is reevaluated when a different query string is provided.
 * Not having a value for apiMockConfig in the URL means the state remains unchanged
 *
 * @param path The endpoint to check
 */
export const isMocked = (path: string) => {
  if (useRuntimeConfig().public.targetEnv === 'PROD')
    return false

  const mockConfig = generateApiMockConfig()
  const [method] = findObjectEntry(apiPaths, path) || []

  if (method) {
    const [client] = Object.entries(apiPaths).find(([_, methods]) => !!methods[method]) || []

    if (client)
      return mockConfig[client]?.[method]
  }

  return true
}
