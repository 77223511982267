<template>
  <nav
    :aria-label="$t.primaryNavigationLabel"
    class="flex items-center overflow-hidden wrap <lg:hidden"
    :class="brandClasses.nav"
    style="height: 2.35rem"
    @mouseleave="menuHovered = false"
  >
    <cms-section
      v-for="menuItem in content.children"
      :key="menuItem.id"
      class="h-full children:h-full"
      :section="{ name: menuItem.id, items: [{ ...menuItem, isFullWidth: true }] }"
    />
  </nav>
</template>

<script lang="ts" setup>
import { HeaderContextKey } from '#commerce/components/vf/header/context'
import type { MegaMenuContent } from '#types/components/cms/mega-menu'

const { content } = defineProps<{
  content: MegaMenuContent
}>()

const { menuHovered } = inject(HeaderContextKey)!
const { brandClasses } = useAppConfig().components.cms.megaMenu
</script>
