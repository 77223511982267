/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { KeysOf } from "#app/composables/asyncData";
import { isMocked, mockPath } from "#root/api/util/mocking";
import type { ApiOptions, UseApiOptions } from "../http-client";
import { HttpClient } from "../http-client";
import type { CollectionPointsData, CollectionPointsPayload } from "./data-contracts";

export class Stores extends HttpClient {
  static paths = { collectionPoints: "/stores/v1/collectionPoints" };
  static mockDataPaths = {};
  static mockedPaths = { collectionPoints: false };
  static onResponseHandlers = {};
  constructor(baseParams) {
    super(baseParams, "stores");
  }

  /**
   * No description
   *
   * @tags Stores
   * @name CollectionPoints
   * @summary Collection Points
   * @request POST:/stores/v1/collectionPoints
   */
  collectionPoints = <
    ResT = CollectionPointsData,
    DataT = ResT,
    PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
    DefaultT = null,
  >(
    data: CollectionPointsPayload,
    params: UseApiOptions<ResT, DataT, PickKeys, DefaultT> = {},
  ) =>
    this.request<ResT, DataT, PickKeys, DefaultT>({
      path: isMocked("/stores/v1/collectionPoints")
        ? computed(() => mockPath("Stores", "collectionPoints", ``))
        : computed(() => `/stores/v1/collectionPoints`),
      method: "POST",
      body: data,
      key: "/stores/v1/collectionPoints",
      apiName: "Stores.collectionPoints",
      ...params,
    });
  /**
   * No description
   *
   * @tags Stores
   * @name CollectionPoints
   * @summary Collection Points
   * @request POST:/stores/v1/collectionPoints
   */
  $collectionPoints = (data: CollectionPointsPayload, requestParams: ApiOptions = {}) =>
    this.$request<CollectionPointsData>({
      path: isMocked("/stores/v1/collectionPoints")
        ? mockPath("Stores", "collectionPoints", ``)
        : `/stores/v1/collectionPoints`,
      method: "POST",
      body: data,
      apiName: "Stores.collectionPoints",
      ...requestParams,
    });
}
