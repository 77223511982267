<template>
  <cms-shared-button v-if="target" ref="elementRef" v-bind="target" @click="handlePromotionClick" />
</template>

<script lang="ts" setup>
import { SectionContextKey } from '#content/components/cms/section/context'
import type { CtaButtonContent } from '#types/components/cms/cta-button'

const { content } = defineProps<{ content: CtaButtonContent }>()

const { promotionTracking, target } = content

const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)
</script>
