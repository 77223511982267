export const useLoyaltyStore = defineStore('loyalty', () => {
  const { consumer } = useApi()
  const { data: vouchers, error, pending, execute } = consumer.getLoyaltyVouchers({ immediate: false })

  const currentReward = computed(() => {
    const currentPoints = vouchers.value?.currentLoyaltyPoints || 0
    const amount = vouchers.value?.applicableLoyaltyVouchers.reduce((acc, voucher) => {
      return currentPoints >= voucher.points ? Number(voucher.amountInCurrency) : acc
    }, 0) || 0

    return useFormattedPrice(amount, useRuntimeConfig().public.currency[useLocale()], { minimumFractionDigits: 0 })
  })

  const reset = () => {
    vouchers.value = null
  }
  return {
    get: execute,
    error,
    pending,
    vouchers,
    currentReward,
    reset
  }
})
