export const useDialogsStore = defineStore('dialogs', () => {
  const { $viewport } = useNuxtApp()
  const { allowSimplifiedEnrollment } = useFeatureFlags()
  const { signIn } = useAppConfig().components.dialog

  return {
    ModalSignIn: createDialog(allowSimplifiedEnrollment ? 'simplified-enrollment' : 'sign-in', { wrapper: () => signIn.wrapper }),
    ModalSignUp: createModal('sign-up'),
    ModalForgotPassword: createModal('forgot-password'),
    ModalInterests: createModal('interests'),
    ModalKlarna: createModal('klarna'),
    ModalLoyaltySignUp: createModal('loyalty-sign-up'),
    ModalReviewUgc: createModal('review-ugc'),
    ModalReportIssue: createModal('report-issue'),
    ModalLocationConfirmation: createModal('location-confirmation'),
    ModalEmployeeTerms: createModal('employee-terms'),
    ModalThreeDsChallenge: createModal('three-ds-challenge'),
    ModalAskQuestionSuccess: createModal('ask-question-success'),
    ModalAddGiftOption: createModal('add-gift-option'),
    ModalWelcome: createModal('welcome'),
    ModalOrPanelChangeStoreConfirmation: createDialog('change-store-confirmation', {
      wrapper: () => $viewport.md ? 'modal' : 'panel'
    }),
    ModalOrPanelPickupInStore: createDialog('pickup-in-store', {
      wrapper: () => $viewport.md ? 'modal' : 'panel'
    }),
    PanelCartChangeShippingMethod: createPanel('cart-change-shipping-method'),
    PanelMiniCart: createPanel('mini-cart')
  }
})
