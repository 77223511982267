<template>
  <cms-video-player
    v-if="!isLongCloudinaryVideo"
    :content="{
      id: content.id,
      type: content.type,
      media: { sm: content },
      playerSettings: {
        autoplay: true,
        hideControls: false,
        loop: true,
        muted: true,
      },
    }"
  />
  <lazy-cms-video-cloudinary-player v-else v-bind="content" />
</template>

<script setup lang="ts">
import type { CMSVideo } from '#types/media'

const { content } = defineProps<{ content: CMSVideo }>()
const { $feature } = useNuxtApp()

const isCloudinaryVideo = content.cloudinaryAsset?.cloudName && content.cloudinaryAsset?.publicId
const LONG_VIDEO_LENGTH = 30
const isLongCloudinaryVideo = $feature.enableCmsVideoCloudinaryPlayer
  && isCloudinaryVideo && content.duration > LONG_VIDEO_LENGTH
</script>
