<template>
  <video :id="`player-${id}`" class="cld-video-player" />
</template>

<script setup lang="ts">
const {
  id,
  cloudinaryAsset,
  url,
  seoTitle,
  seoDescription,
  width,
  height
} = defineProps<{
  id: string
  cloudinaryAsset?: {
    cloudName: string
    publicId: string
  }
  url: string
  seoTitle?: string
  seoDescription?: string
  width: number
  height: number
}>()

onMounted(async () => {
  import('cloudinary-video-player/cld-video-player.min.css')
  const cloudinary = await import('cloudinary-video-player')

  const player = cloudinary.videoPlayer(`player-${id}`, {
    cloudName: cloudinaryAsset!.cloudName,
    secureDistribution: url.split('/')[2],
    width,
    height,
    fluid: true,
    controls: false,
    muted: true,
    hideContextMenu: true,
    autoplayMode: 'on-scroll',
    autoplay: true,
    loop: true,
  })

  player.source(cloudinaryAsset!.publicId, {
    sourceTypes: ['hls'],
    info: {
      title: seoTitle,
      subtitle: seoDescription,
    }
  })
})
</script>
