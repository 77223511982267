import type { LabelValues } from 'prom-client'
import type { AccumulatedMeasurement, CanvasPerformanceMeasure } from '#types/performance'

declare global {
  interface Window {
    measureBatch: AccumulatedMeasurement[]
  }
}

if (import.meta.client)
  window.measureBatch = []

/**
 * Provides the performance measurements that have been added to the current batch for ingestion
 * @returns The current array of measurements
 * @category Utils
 */
export function getAccumulatedPerformanceMeasurements() {
  return window.measureBatch
}

/**
 * Clears the currently accumulated performance measurements;
 * meant to be called after they've been posted to the server endpoint
 * @category Utils
 */
export function clearAccumulatedPerformanceMeasurements() {
  window.measureBatch = []
}

/**
 * Accumulates a performance measurement
 * @category Utils
 * @param measure The measurement to accumulate
 * @param labels  The labels to attach to the measurement
 */
export function accumulatePerformanceMeasurement(measure: CanvasPerformanceMeasure, labels: LabelValues<string>) {
  window.measureBatch.push({
    labels,
    measure: {
      name: measure.name,
      duration: measure.duration
    }
  })
}

/**
 * Sends the accumulated or passed in performance measurements to the ingestion API endpoint
 * @category Utils
 * @param measures the measurement to send; falls back to the globally accumulated ones
 */
export function ingestPerformanceMeasures(measures?: AccumulatedMeasurement[]) {
  const URL = '/fapi/performance/ingest'
  const measureBatch = measures ?? getAccumulatedPerformanceMeasurements()

  if (measureBatch.length) {
    if (import.meta.client) {
      navigator.sendBeacon(URL, JSON.stringify({ measureBatch }))
    }
    else {
      $fetch(URL, {
        method: 'POST',
        body: { measureBatch }
      })
    }

    if (!measures)
      clearAccumulatedPerformanceMeasurements()
  }
}
