import type { ShippingAddress } from '#types/cart'

declare global {
  interface Window {
    Klarna: Record<string, any>
  }
}

interface KlarnaAuthorizeResponse {
  payment_instrument_id: string
  sessionID: string
  clientToken: string
  at: string
}

const getAddressField = (address: ShippingAddress) => ({
  given_name: address.firstName,
  family_name: address.lastName,
  email: address.email,
  title: '',
  street_address: address.addressLine2 ? `${address.addressLine1} ${address.addressLine2}` : address.addressLine1,
  postal_code: address.postalCode,
  city: address.city,
  region: address.province,
  phone: address.phone,
  country: address.country
})

export default (billingAddress?: MaybeRefOrGetter<ShippingAddress>) => {
  const { $script } = useScript('https://x.klarnacdn.net/kp/lib/v1/api.js', { trigger: 'manual' })
  const cart = useCartStore()
  const config = useState<Record<string, any>>('klarnaConfig', () => ({}))
  const inited = useState<boolean>('klarnaInited', () => false)
  const provider = useState<'CYBERSOURCE' | 'ADYEN' | undefined>('klarnaProvider')

  const init = async (basketId) => {
    if (!inited.value) {
      await $script.load()
      inited.value = true
    }

    const _billingAddress = toValue(billingAddress)

    const session = await useApi().cart.$getPaymentSession({
      referer: window.location.origin,
      paymentMethodId: 'CREDIT_CARD',
      paymentMethod: 'klarna'
    }, {
      headers: {
        'content-type': 'application/json'
      }
    })

    if (!session.success) throw session

    provider.value = session.provider

    // if is pickup or sts don't run billing address as the payment instrument will have wrong billing address
    if (_billingAddress && provider.value === 'ADYEN' && !isPickupOrSts(cart.shippingMethods[0].code))
      await cart.addBillingAddress(_billingAddress)

    const { payment_instruments } = await useApi().cart.$addPaymentInstrument(basketId, {
      payment_method_id: 'KLARNA'
    })

    config.value = payment_instruments
      .find(({ payment_method_id }) => payment_method_id === 'KLARNA')

    window.Klarna.Payments.init(config.value)
  }

  const load = (container: string | HTMLElement) => window.Klarna.Payments.load({
    container,
    payment_method_category: config.value?.payment_method_categories?.[0]?.identifier
  })

  const authorize
  = (billingAddress: ShippingAddress, shippingAddress: ShippingAddress): Promise<KlarnaAuthorizeResponse> =>
    new Promise((resolve, reject) => window.Klarna.Payments.authorize(
      { payment_method_category: config.value?.payment_method_categories?.[0]?.identifier },
      isPickupOrSts(cart.shippingMethods[0].code)
        ? {
            billing_address: getAddressField(billingAddress),
            shipping_address: getAddressField(shippingAddress),
            attachment: config.value.attachment
          }
        : {},
      (klarnaResponse) => {
        if (klarnaResponse.approved) {
          resolve({
            payment_instrument_id: config.value.payment_instrument_id,
            sessionID: config.value.session_id,
            clientToken: config.value.client_token,
            at: klarnaResponse.authorization_token
          })
        }
        else {
          // if klarnaResponse.approved is false, it means the user has closed the Klarna modal or there was an error authorizing Klarna.
          // https://docs.klarna.com/klarna-payments/additional-resources/klarna-payments-sdk-reference/#authorizecallback
          if (klarnaResponse.show_form) {
            // If show_form is true, it means the user has closed the Klarna modal. Silently reject.
            // eslint-disable-next-line prefer-promise-reject-errors
            reject()
          }
          else {
            // Actual error authorizing Klarna. Throw an error.
            reject(new Error('Error authorizing Klarna'))
          }
        }
      }
    ))

  return {
    load,
    init,
    authorize,
  }
}
