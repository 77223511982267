import type { FetchContext, FetchRequest, FetchResponse } from 'ofetch'
import type { ApiErrorCode } from '#root/enums/api'

interface ApiErrorOptions<T = any> {
  request?: FetchRequest
  response?: FetchResponse<T>
  statusCode?: number
  cause?: unknown
}

export class ApiError<T = any> extends Error {
  name = 'ApiError'
  errorId: ApiErrorCode
  request?: FetchRequest
  response?: FetchResponse<T>
  errorDetails?: any

  constructor(message: string, errorId: ApiErrorCode, options?: ApiErrorOptions) {
    super(message, { cause: options?.cause })
    this.errorId = errorId

    Object.assign(this, options)
  }
}

export const apiResponseErrorHandler = ({ request, response }: FetchContext<any> & {
  response: FetchResponse<ResponseType>
}, $t: any, $dtrum?: Window['dtrum']) => {
  // MuleSoft error response
  const { errorId, message } = response._data?.errorDetails?.[0] || {}

  // HUMAN error response
  const { appId } = response._data

  if (response.status === 403 && appId) {
    window.dispatchEvent(new CustomEvent('pxHandleAutoABR', {
      detail: {
        response: response._data,
        responseUrl: response.url
      }
    }))
  }

  if (!errorId) return

  const apiMessage = $t.apiMessages[errorId] && replaceAll($t.apiMessages[errorId], $t.apiMessages.general)

  if (import.meta.client && $dtrum) {
  // Create an action by entering it and getting the ID
    const actionId = $dtrum.enterAction('API Error Received')
    // Add the necessary action properties
    $dtrum.addActionProperties(actionId, null, null, {
      warningmessage: apiMessage, // get error message returned from MuleSoft
      warningmessageid: errorId, // get the error ID for the warning message. Example "AUT401"
      warningtransactionid: response._data.correlationId
    })
    // leave the action to send the data
    $dtrum.leaveAction(actionId)
  }

  throw new ApiError(apiMessage || message, errorId, {
    statusCode: response.status,
    request,
    response
  })
}
