<template>
  <base-picture
    v-if="images"
    v-bind="images"
    fit="cover"
    class="w-full"
  />
  <base-video
    v-if="video"
    v-bind="video"
    :controls="false"
    autoplay
    loop
    class="w-full cover"
  />
</template>

<script lang="ts" setup>
import type { PlayerSettings } from '#types/components/cms/video-player'
import type { ResponsiveMedia } from '#types/content'

const { media } = defineProps<{
  media: ResponsiveMedia
  playerSettings?: PlayerSettings
}>()

const { getMedia } = useCms()

const { images, video } = (media && getMedia(media)) || {} as ResponsiveMedia
</script>
