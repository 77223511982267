import { ApiError } from '#root/api/util/error'
import { getRegion, getSiteId } from '#core/utils/i18n' // Needs to be imported explicitly for use in prefetch-monetate.ts
import type { ApiConfig } from '#types/config/api'
import type { CountryCode, LocaleCode } from '#types/locale'

/**
 * Asserts that the provided error is an instance of ApiError.
 * @param error The error to be asserted.
 * @throws Throws the error if it is not an instance of ApiError.
 * @category Utils
 */
export const assertApiError = (error): asserts error is ApiError => {
  if (!(error instanceof ApiError)) throw error
}

/**
 * Converts a locale code to a format suitable for API requests.
 * @param locale The locale code to be converted.
 * @returns The converted locale code.
 * @category Utils
 */
export const getApiLocale = (locale: LocaleCode) => locale.replace(/-(\w+)/, (_, g) => `_${g.toUpperCase()}`)

/**
 * Retrieves headers for API requests.
 * @returns The headers object for API requests.
 * @category Utils
 */
export const getApiHeaders = (
  brandPrefix: ApiConfig['brandPrefix'],
  clientApp: ApiConfig['clientApp'],
  countryCode: Uppercase<CountryCode>
) => {
  return {
    siteId: getSiteId(brandPrefix, countryCode),
    channel: 'ECOMM',
    brand: brandPrefix,
    region: getRegion(countryCode),
    source: 'ECOM15',
    clientApp
  }
}
